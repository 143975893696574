import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

/* Project */
import useForm from 'hooks/useForm';
import { setActiveStep } from 'content/app.actions';
import { darkerGreyOnForms } from 'utils/helper';
import { controlsChanged } from '../schedulerSteps.actions';
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function LocationForm(props) {
  const { submitButtonRef, onChange, errors } = props;

  const dispatch = useDispatch();

  const regions = useSelector((state) => state.app.objects.regions);
  const workshops = useSelector((state) => state.app.objects.workshops);
  const controlRegion = useSelector((state) => state.schedulerSteps.controls.region);
  const controlWorkshop = useSelector((state) => state.schedulerSteps.controls.workshop);

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const onChangeSelect = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange],
  );

  const onChangeRegion = useCallback(
    (event) => {
      onChangeText('workshop', '');
      onChange(event);
    },
    [onChange],
  );

  const regionsList = useMemo(
    () => Object.values(regions).sort((a, b) => +a.externalId - +b.externalId),
    [regions],
  );

  const workshopsList = useMemo(() => Object.values(workshops), [workshops]);

  const filteredWorkshops = useMemo(
    () => workshopsList.filter((item) => item.region.externalId === controlRegion),
    [workshopsList, controlRegion],
  );

  return (
    <>
      <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
        <InputLabel id="demo-simple-select-label" sx={{ ...darkerGreyOnForms }}>
          Región
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Región"
          fullWidth
          id="region"
          name="region"
          onChange={onChangeRegion}
          value={controlRegion}
          error={!!errors.region[0]}>
          {regionsList.length ? (
            regionsList.map((item) => (
              <MenuItem
                key={item.externalId}
                value={item.externalId}
                sx={SchedulerStepsStyles.menuItem}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled sx={SchedulerStepsStyles.menuItem}>
              No hay datos disponibles.
            </MenuItem>
          )}
        </Select>
        <FormHelperText error={!!errors.region[0]}>{errors.region[0]}</FormHelperText>
      </FormControl>
      <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
        <InputLabel id="demo-simple-select-label" sx={{ ...darkerGreyOnForms }}>
          Taller
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Taller"
          fullWidth
          id="workshop"
          name="workshop"
          onChange={onChangeSelect}
          value={controlWorkshop}
          disabled={controlRegion === ''}
          error={!!errors.workshop[0]}>
          {filteredWorkshops.length > 0 ? (
            filteredWorkshops.map((item) => (
              <MenuItem key={item.id} value={item.id} sx={SchedulerStepsStyles.menuItem}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled sx={SchedulerStepsStyles.menuItem}>
              No hay talleres disponibles en esta región.
            </MenuItem>
          )}
        </Select>
        <FormHelperText error={!!errors.workshop[0]}>{errors.workshop[0]}</FormHelperText>
      </FormControl>
      <input type="submit" style={{ display: 'none' }} ref={submitButtonRef} />
    </>
  );
}

LocationForm.propTypes = {
  submitButtonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

LocationForm.defaultProps = {
  submitButtonRef: { current: null },
};

export default LocationForm;
