/* eslint-disable */
import * as React from 'react';

/* Material UI */
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import colors from 'utils/colors';

const ColorlibConnector = styled(StepConnector)(({}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 'calc(50% - 2px)',
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.lightBlue,
      opacity: 1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.lightBlue,
      opacity: 1,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.lightBlue,
    opacity: 0.5,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function StepsMobile(props) {
  const { steps, activeStep } = props;

  return (
    <Stack sx={{ width: '100%' }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{ active: activeStep >= i }}
              key={label}
              StepIconComponent={label}>
              {}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default StepsMobile;
