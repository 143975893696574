import React, { useEffect } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import Grid from '@mui/material/Grid';

/* Project */
import Steps from 'content/features/steps/Steps';
import StyledContainer from 'content/containers/StyledContainer';
import DateForm from 'content/features/schedulerSteps/DateForm';
import ResumeView from 'content/features/schedulerSteps/ResumeView';
import SelectReschedule from 'content/features/schedulerSteps/SelectReschedule';
import VehicleOwnerStep from 'content/features/schedulerSteps/VehicleOwnerStep';
import { setResetSteps } from 'content/app.actions';
import colors from 'utils/colors';
import { Typography } from '@mui/material';

function Rescheduler({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeStep = useSelector((state) => state.app.activeStep);
  const view = useSelector((state) => state.app.view);

  useEffect(() => {
    if (view === '') {
      navigate('/reschedule');
    }
  }, [view]);

  const titles = [
    '1. Selección del Servicio',
    '2. Datos del Propietario',
    '3. Horario del Servicio',
    '4. Resumen del Agendamiento',
  ];

  const getStepTitle = () => {
    const title = titles[activeStep];

    return title || null;
  };

  const views = [<SelectReschedule />, <VehicleOwnerStep />, <DateForm />, <ResumeView />];

  const getStepContent2 = () => {
    const component = views[activeStep];

    return component || null;
  };

  useEffect(
    () => () => {
      dispatch(setResetSteps());
    },
    [dispatch],
  );

  return (
    <StyledContainer title={title}>
      <Steps stepsNumber={3}>
        <Grid
          style={{
            marginTop: 30,
            marginBottom: 30,
            paddingLeft: 85,
            paddingRight: 85,
            border: `2px solid ${colors.lightBlue}`,
            borderRadius: 10,
          }}>
          <Typography
            style={{
              paddingLeft: 16,
              paddingTop: 18,
              paddingBottom: 8,
              marginLeft: -85,
              marginRight: -85,
              marginBottom: 24,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
            }}
            bgcolor={colors.lightBlue}
            color={colors.white}
            variant="h6">
            {getStepTitle()}
          </Typography>
          <Grid style={{ marginTop: '30px' }}>{getStepContent2()}</Grid>
        </Grid>
      </Steps>
    </StyledContainer>
  );
}

export default Rescheduler;
