import React from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

/* Project */
import { anyFalseInObject, anyTruhtyInObject } from 'utils/functions';
import LoaderDialog from 'content/features/loaders/LoaderDialog';
import { darkerGreyOnForms } from 'utils/helper';
import {
  controlsChanged,
  getUserVehicle,
  clearVehicleFormValues,
  getUserVehicleWithConectaApi,
  setControlsErrors,
} from '../../schedulerSteps.actions';
import SchedulerStepsStyles from '../../SchedulerSteps.styles';

const disabledFormValue = ({ loading, isFindedVehicle }) => loading || isFindedVehicle;

const executeActionOnGetUserVehicleData = ({ conectaApiLogic, patent }) =>
  conectaApiLogic ? getUserVehicleWithConectaApi({ patent }) : getUserVehicle({ patent });

const getIconOfButton = ({ isFindedVehicle }) => (isFindedVehicle ? <ClearIcon /> : <SearchIcon />);
// TODO: SET FIRENT MESSAGE ON PATENT ERRORS
const patentRequiredFieldValidation = (patent) => {
  const validations = {
    notEmptyValue: patent === '',
    notEmptyValueWithTrim: patent.trim().length === 0,
  };

  const isInvalidField = anyTruhtyInObject(validations);

  return isInvalidField ? 'Campo patente requerido' : '';
};

const patentFormatRequiredFieldValidation = (patent) => {
  const validations = {
    minLengthValidation: patent?.toString()?.length >= 6,
  };

  const isInvalidField = anyFalseInObject(validations);

  return isInvalidField ? 'Campo patente inválido' : '';
};

function FindVehicleSection() {
  const dispatch = useDispatch();
  const patentState = useSelector((state) => state.schedulerSteps.controls.patent);
  const patentError = useSelector((state) => state.schedulerSteps.controlsErrors.patent);

  const isFindedVehicleState = useSelector((state) => state.schedulerSteps.isFindedVehicle);
  const loading = useSelector((state) => state.schedulerSteps.loading);
  const disableSearchButton =
    patentRequiredFieldValidation(patentState).length !== 0 ||
    patentFormatRequiredFieldValidation(patentState).length !== 0;

  const findVehicleValidation = ({ patent, isFindedVehicle }) =>
    patent?.toString()?.length > 0 && !isFindedVehicle;
  const handleBlurPatentField = ({ name, value }) => {
    let errorMessage = '';
    errorMessage = patentRequiredFieldValidation(value);
    if (errorMessage === '') {
      errorMessage = patentFormatRequiredFieldValidation(value);
    }

    dispatch(
      setControlsErrors({
        controlsErrors: {
          [name]: errorMessage,
        },
      }),
    );
  };
  const onChange = (event) => {
    const { value, name } = event.target;
    dispatch(controlsChanged({ name, value }));
    if (name === 'patent') {
      handleBlurPatentField({ name, value });
    }
  };

  const handleClickButton = (event) => {
    event.preventDefault();
    if (findVehicleValidation({ patent: patentState, isFindedVehicle: isFindedVehicleState })) {
      const conectaApiLogic = process.env.REACT_APP_CONECTA_API_LOGIC;
      return dispatch(executeActionOnGetUserVehicleData({ conectaApiLogic, patent: patentState }));
    }
    return dispatch(clearVehicleFormValues());
  };

  return (
    <Grid item xs={12} sm={12} md={5} lg={4} xl={3} sx={SchedulerStepsStyles?.patentContainer}>
      <TextField
        id="patent"
        name="patent"
        key="patent"
        label="Patente"
        placeholder="EJ: ABCD12"
        variant="outlined"
        InputLabelProps={{
          sx: { ...darkerGreyOnForms },
        }}
        size="small"
        sx={SchedulerStepsStyles?.patentField}
        fullWidth
        value={patentState}
        onChange={onChange}
        error={!!patentError}
        helperText={patentError}
        disabled={disabledFormValue({ loading, isFindedVehicleState })}
      />
      <LoadingButton
        loading={loading}
        color="lightBlue"
        variant="contained"
        fullWidth
        disabled={disableSearchButton}
        sx={SchedulerStepsStyles?.searchButton}
        onClick={handleClickButton}>
        {getIconOfButton({ isFindedVehicle: isFindedVehicleState })}
      </LoadingButton>
      <LoaderDialog open={loading} title="Buscando datos vehículo" />
    </Grid>
  );
}

export default FindVehicleSection;
