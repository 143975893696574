import React, { useMemo } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Hidden,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import ContactButtons from './ContactButtons';
import { removeContact } from '../schedulerSteps.actions';
import colors from 'utils/colors';
import { Edit } from '@mui/icons-material';

function AddContact(props) {
  const dispatch = useDispatch();
  const { toggleCreateDialog, toggleEditDialog, handleChangeTable, handleChangeContact, rows } =
    props;

  const selectedIndexContact = useSelector(
    (state) => state.schedulerSteps.controls.selectedIndexContact,
  );

  const selectedContactError = useSelector(
    (state) => state.schedulerSteps.controlsErrors.selectedContact,
  );

  const isFindedUser = useSelector((state) => state.schedulerSteps.isFindedUser);
  const email = useSelector((state) => state.schedulerSteps.controls.email);

  const filteredContacts = useMemo(() => {
    let contacts;
    if (isFindedUser && email !== '') {
      contacts = rows.filter((item) => item.email !== ' ' && item.email !== email);
    } else {
      contacts = rows;
    }
    return contacts;
  }, [rows, isFindedUser, email]);

  const mobileTableHeader = (
    <TableHead sx={SchedulerStepsStyles.mobileTableHeader}>
      <TableRow>
        <Hidden mdDown>
          <TableCell align="left">Seleccionar</TableCell>
        </Hidden>
        <Hidden mdUp>
          <TableCell align="left" />
        </Hidden>
        <TableCell align="left" sx={{ color: '#fff' }}>
          Nombre
        </TableCell>
        <TableCell align="left" sx={{ color: '#fff' }}>
          Correo
        </TableCell>
        <Hidden mdDown>
          <TableCell align="left">Teléfono</TableCell>
        </Hidden>
        <TableCell align="left" />
      </TableRow>
    </TableHead>
  );

  const desktopTableHeader = (
    <TableHead>
      <TableRow sx={{ backgroundColor: colors.blue, color: colors.white }}>
        <Hidden mdDown>
          <TableCell align="left">
            <Typography color={colors.white}>Seleccionar</Typography>
          </TableCell>
        </Hidden>
        <Hidden mdUp>
          <TableCell align="left" />
        </Hidden>
        <TableCell color="white" align="left">
          <Typography color={colors.white}>Nombre</Typography>
        </TableCell>
        <Hidden mdDown>
          <TableCell align="left">
            <Typography color={colors.white}>Correo</Typography>
          </TableCell>
          <TableCell align="left">
            <Typography color={colors.white}>Teléfono</Typography>
          </TableCell>
        </Hidden>
        <TableCell align="left" />
      </TableRow>
    </TableHead>
  );

  const editCreatedContact = () => {
    return;
  };

  const removeCreatedContact = () => {
    const cleanContacts = filteredContacts.filter((item) => !item.fromSite);
    return dispatch(removeContact(cleanContacts));
  };

  console.log(filteredContacts);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} id="form-section">
      <Grid item xs={12} sx={SchedulerStepsStyles.addContactTitleContainer}>
        <Typography color="primary" variant="body1" gutterBottom sx={{ marginTop: '20px' }}>
          Ingresa los datos de la persona que entregará el vehículo
        </Typography>
        <ContactButtons
          rows={filteredContacts}
          toggleCreateDialog={toggleCreateDialog}
          toggleEditDialog={toggleEditDialog}
          handleChangeContact={handleChangeContact}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <Hidden mdUp>{mobileTableHeader}</Hidden>
          <Hidden mdDown>{desktopTableHeader}</Hidden>
          <TableBody>
            {filteredContacts.length ? (
              filteredContacts.map((row, index) => (
                <TableRow
                  key={`${row.externalId}${row.email}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">
                    <Checkbox
                      size="small"
                      checked={index === selectedIndexContact}
                      onChange={() => handleChangeTable(row, index)}
                    />
                  </TableCell>
                  <TableCell align="left">{`${row.name} ${row.lastName}`}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <Hidden mdDown>
                    <TableCell align="left">{row.phone}</TableCell>
                  </Hidden>
                  <TableCell>
                    {row.fromSite && (
                      <>
                        <IconButton aria-label="delete" onClick={editCreatedContact}>
                          <Edit color="lightBlue" />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={removeCreatedContact}>
                          <DeleteIcon color="lightBlue" />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    width: '100%',
                  },
                }}>
                <TableCell align="center" colSpan={5}>
                  No posee datos de contacto
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FormHelperText error={!!selectedContactError} sx={{ mt: 2 }}>
        {selectedContactError}
      </FormHelperText>
    </Grid>
  );
}

export default AddContact;
