/**
 * @type {import('@mui/material').ThemeOptions}
 */

import { stepLabelClasses } from '@mui/material';
import colors from './colors';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    primary: {
      main: '#002855',
    },
    red: {
      main: '#E10600',
      dark: '#E10600',
      light: '#E10600',
      contrastText: '#fff',
      darkContrastText: '#fff',
    },
    blue: {
      main: '#002855',
    },
    lightBlue: {
      main: '#1680E4',
    },
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          [`&.${stepLabelClasses.alternativeLabel}`]: {
            marginTop: 5,
            color: colors.lightBlue,
            fontWeight: 'bold',
          },
          [`&.${stepLabelClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
        iconContainer: {
          padding: 0,
        },
      },
    },
  },
};

export default defaultTheme;
