import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import StyledContainerStyles from './StyledContainer.styles';

function StyledContainer(props) {
  const { children, hideTitle, title = 'AUTO AGENDAMIENTO SERVICIO LIVIANO' } = props;
  return (
    <Grid container sx={StyledContainerStyles.container}>
      <Grid item xs={12}>
        {!hideTitle && (
          <Grid mt={4} mb={4}>
            <Typography variant="h5" color="primary">
              Tu Portal de Agendamiento Taller
            </Typography>
            <Typography fontWeight="bold" variant="h4" color="primary">
              {title}
            </Typography>
          </Grid>
        )}

        {children}
      </Grid>
    </Grid>
  );
}

export default StyledContainer;
