import React, { useCallback, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

/* Project */
import useForm from 'hooks/useForm';
import { formatRutNew, validateEmailForm, validateNames, validateRut } from 'utils/functions';
import { setActiveStep } from 'content/app.actions';
import LoaderDialog from 'content/features/loaders/LoaderDialog';
import { darkerGreyOnForms } from 'utils/helper';
import {
  controlsChanged,
  getUserData,
  clearOwnerVehicleFormValues,
} from '../schedulerSteps.actions';
import SchedulerStepsStyles from '../SchedulerSteps.styles';

const fieldHelperText = (error, text) => error || text;

const getIconOfButton = ({ isFindedUser, isUsed }) =>
  isFindedUser || isUsed ? <ClearIcon /> : <SearchIcon />;

const findOwnerVehicleValidation = ({ errors, isFindedUser, isUsed }) =>
  !errors.rut[0] && !isFindedUser && !isUsed;

const getUpdateRut = (rut) => {
  let newValue = rut.replace(/[-]/g, '');

  if (rut.length > 0) {
    newValue = `${newValue.substring(0, newValue.length - 1)}-${newValue[newValue.length - 1]}`;
  }

  return newValue;
};

/**
 * @param {boolean} isFindedUser
 * @param {string} rut
 * @returns {boolean}
 */
const disableFindButton = (isFindedUser, rut) =>
  isFindedUser ? !(rut?.length === 0) : rut?.length === 0;

function VehicleOwnerForm(props) {
  const { submitButtonRef } = props;
  const [searchState, setSearchState] = useState(true);

  const dispatch = useDispatch();

  const controls = useSelector((state) => state.schedulerSteps.controls);
  const loading = useSelector((state) => state.schedulerSteps.loading);
  const isFindedUser = useSelector((state) => state.schedulerSteps.isFindedUser);
  const view = useSelector((state) => state.app.view);

  const lastNameValidation = (value) => (!isFindedUser ? value.toString().trim().length > 0 : true);

  const onChangeText = useCallback((id, value) => {
    let newValue = value;
    if (id === 'rut') {
      newValue = formatRutNew(value);
    }
    dispatch(controlsChanged({ name: id, value: newValue }));
  }, []);

  const disableFormField = searchState;
  // const disableFormField2 = loading || isFindedUser || controls.disableForm;

  const formFieldsValidation = (validation) => {
    let isValid;
    if (!isFindedUser) {
      isValid = validation;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const { onChange, onSubmit, errors, clearErrors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      rut: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un RUT ',
        },
        {
          check: (value) => validateRut(value),
          message: 'Campo RUT inválido',
        },
      ],
      name: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un nombre',
        },
        {
          check: (value) => validateNames(value),
        },
      ],
      lastName: [
        {
          check: (value) => lastNameValidation(value),
          message: 'Debe ingresar un apellido',
        },
        {
          check: (value) => validateNames(value),
        },
      ],
      phone: [
        {
          check: (value) => formFieldsValidation(value.toString().trim().length > 0),
          message: 'Debe ingresar un teléfono',
        },
        {
          check: (value) => formFieldsValidation(value.toString().trim().length === 9),
          message: 'El teléfono debe contener 9 caracteres',
        },
        {
          check: (value) => formFieldsValidation(/^[0-9]+$/.test(value)),
          message: 'Campo teléfono solo admite números',
        },
      ],
      email: [
        {
          check: (value) => formFieldsValidation(value.toString().trim().length > 0),
          message: 'Debe ingresar un correo',
        },
        {
          check: (value) => validateEmailForm(value.toString().trim(), isFindedUser),
          message: 'Correo inválido',
        },
      ],
    },
  });

  const handleOnBlurRutField = useCallback(
    (event) => {
      const { name, value } = event.target;

      if (errors.rut[0]) return;

      const updatedRut = getUpdateRut(value);

      dispatch(controlsChanged({ name, value: updatedRut }));
    },
    [errors],
  );

  const getUserDataFunc = () => {
    if (findOwnerVehicleValidation({ errors, isFindedUser, isUsed: controls.findButtonUsed })) {
      clearErrors();
      setSearchState(false);
      return dispatch(getUserData({ rut: controls.rut }));
    }
    clearErrors();
    setSearchState(true);
    return dispatch(clearOwnerVehicleFormValues());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(setActiveStep()));
  };

  return (
    <>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container mt="20px" mb="20px" direction="row" gap={2}>
          <Grid item xs={12} sm={12} md={5.8} id="form-section">
            <Grid id="patent-section">
              <Grid sx={SchedulerStepsStyles.rutContainer}>
                <TextField
                  id="rut"
                  name="rut"
                  key="rut"
                  required
                  disabled={view === 'reschedule'}
                  value={controls.rut}
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  onChange={onChange}
                  onBlur={handleOnBlurRutField}
                  inputProps={{ maxLength: 10 }}
                  label="RUT"
                  placeholder="RUT sin puntos y con guión*"
                  variant="outlined"
                  size="small"
                  sx={SchedulerStepsStyles.patentField}
                  // disabled={loading || isFindedUser || controls.findButtonUsed}
                  fullWidth
                  error={!!errors.rut[0]}
                  helperText={fieldHelperText(errors.rut[0], '')}
                />

                <LoadingButton
                  loading={loading}
                  disabled={view === 'reschedule'}
                  color="lightBlue"
                  variant="contained"
                  fullWidth
                  disabled={disableFindButton(loading, controls.rut) || !validateRut(controls.rut)}
                  sx={SchedulerStepsStyles.searchButton}
                  onClick={getUserDataFunc}>
                  {getIconOfButton({ isFindedUser, isUsed: controls.findButtonUsed })}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={5.8}>
            <Typography variant="body2" fontSize={12} sx={SchedulerStepsStyles.helperText}>
              Si ya has agendado previamente con nosotros, al ingresar el RUT los datos se cargarán
              automaticamente. De lo contrario podrá ingresarlos manualmente
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5.8} id="form-section">
            <Grid id="patent-section">
              <Grid sx={SchedulerStepsStyles.fieldVehicleForm}>
                <TextField
                  id="name"
                  name="name"
                  key="name"
                  required
                  disabled={view === 'reschedule'}
                  value={controls.name}
                  onChange={onChange}
                  label="Nombre"
                  variant="outlined"
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  size="small"
                  sx={SchedulerStepsStyles.fieldVehicleForm}
                  fullWidth
                  error={!!errors.name[0]}
                  helperText={fieldHelperText(errors.name[0], 'Nombre del dueño del vehículo')}
                  // disabled={disableFormField}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5.8} id="form-section">
            <Grid id="patent-section">
              <Grid sx={SchedulerStepsStyles.fieldVehicleForm}>
                <TextField
                  id="lastName"
                  name="lastName"
                  key="lastName"
                  required
                  disabled={view === 'reschedule'}
                  value={controls.lastName}
                  onChange={onChange}
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  label="Apellido"
                  variant="outlined"
                  size="small"
                  sx={SchedulerStepsStyles.fieldVehicleForm}
                  fullWidth
                  error={!!errors.lastName[0]}
                  helperText={fieldHelperText(
                    errors.lastName[0],
                    'Apellido del dueño del vehículo',
                  )}
                  // disabled={disableFormField}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5.8} id="form-section">
            <Grid id="patent-section">
              <Grid sx={SchedulerStepsStyles.fieldVehicleForm}>
                <TextField
                  id="phone"
                  name="phone"
                  key="phone"
                  required
                  value={controls.phone}
                  onChange={onChange}
                  label="Teléfono"
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  variant="outlined"
                  size="small"
                  sx={SchedulerStepsStyles.fieldVehicleForm}
                  fullWidth
                  error={!!errors.phone[0]}
                  helperText={fieldHelperText(errors.phone[0], 'Teléfono principal')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ ...darkerGreyOnForms }}>+56</Typography>
                      </InputAdornment>
                    ),
                  }}
                  // disabled={disableFormField}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5.8} id="form-section">
            <Grid id="patent-section">
              <Grid sx={SchedulerStepsStyles.fieldVehicleForm}>
                <TextField
                  id="email"
                  name="email"
                  key="email"
                  required
                  value={controls.email}
                  onChange={onChange}
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  label="Correo"
                  variant="outlined"
                  size="small"
                  sx={SchedulerStepsStyles.fieldVehicleForm}
                  fullWidth
                  error={!!errors.email[0]}
                  helperText={fieldHelperText(errors.email[0], 'Correo principal')}
                  // disabled={disableFormField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <input type="submit" style={{ display: 'none' }} ref={submitButtonRef} />
      </form>
      <LoaderDialog open={loading} title="Buscando datos dueño de vehículo" />
    </>
  );
}

export default VehicleOwnerForm;
