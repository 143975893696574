import React from 'react';

/* Material UI */
import { Box, Grid } from '@mui/material';

function ActionsButtonsContainer({ children }) {
  return (
    <>
      <Grid
        style={{ marginLeft: -65, marginRight: -65 }}
        sx={{
          position: 'sticky',
          bottom: '86.5px',
          height: '1px',
          zIndex: 900,
          boxShadow: '0 -0.5px 0px rgba(0, 0, 0, 0.1)',
        }}
      />
      <Grid
        sx={{
          zIndex: 1000,
          position: 'sticky',
          paddingBottom: '10px',
          bottom: 0,
          backgroundColor: '#fff',
        }}
        item
        xs={12}>
        {children}
      </Grid>
    </>
  );
}

export default ActionsButtonsContainer;
