import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BuildIconm from '@mui/icons-material/Build';
import AccountCircleIconm from '@mui/icons-material/AccountCircle';
import CalendarTodayIconm from '@mui/icons-material/CalendarToday';
import ChecklistRtlIconm from '@mui/icons-material/ChecklistRtl';

import colors from 'utils/colors';

const CarIcon = ({ active }) => (
  <DirectionsCarIcon
    fontSize="large"
    sx={{
      color: colors.white,
      backgroundColor: colors.lightBlue,
      opacity: active ? 1 : 0.44,
      borderRadius: '50%',
      padding: '5px',
    }}
  />
);

const BuildIcon = ({ active }) => (
  <BuildIconm
    fontSize="large"
    sx={{
      color: colors.white,
      backgroundColor: colors.lightBlue,
      opacity: active ? 1 : 0.5,
      borderRadius: '50%',
      padding: '7px',
    }}
  />
);

const AccountCircleIcon = ({ active }) => (
  <AccountCircleIconm
    fontSize="large"
    sx={{
      color: colors.white,
      backgroundColor: colors.lightBlue,
      opacity: active ? 1 : 0.44,
      borderRadius: '50%',
      padding: '5px',
    }}
  />
);

const CalendarTodayIcon = ({ active }) => (
  <CalendarTodayIconm
    fontSize="large"
    sx={{
      color: colors.white,
      backgroundColor: colors.lightBlue,
      opacity: active ? 1 : 0.44,
      borderRadius: '50%',
      padding: '7px',
      overflow: 'visible',
    }}
  />
);

const ChecklistRtlIcon = ({ active }) => (
  <ChecklistRtlIconm
    fontSize="large"
    sx={{
      color: colors.white,
      backgroundColor: colors.lightBlue,
      opacity: active ? 1 : 0.44,
      borderRadius: '50%',
      padding: '5px',
    }}
  />
);

export const labelsIcons = ['Vehículo', 'Servicios', 'Propietario', 'Horario', 'Resumen'];
export const labelsIcons2 = ['Servicio', 'Propietario', 'Horario', 'Resumen'];

export default {
  3: [BuildIcon, AccountCircleIcon, CalendarTodayIcon, ChecklistRtlIcon],
  6: [CarIcon, BuildIcon, AccountCircleIcon, CalendarTodayIcon, ChecklistRtlIcon],
};
