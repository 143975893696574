const DialogStyles = {
  dialogTitle: {
    textAlign: 'center',
    color: '#002855',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  dialogContent: {
    color: '#002855',
    padding: '10px 0px 10px 0px',
    textAlign: 'justify',
  },
  dialogIcon: {
    marginRight: 2,
  },
  iconContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  errorIcon: {
    fontSize: 70,
    mb: 2,
    color: '#F50057',
  },
};

export default DialogStyles;
