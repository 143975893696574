import React from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/* Project */
import { setPreviousStep } from 'content/app.actions';
import { useNavigate } from 'react-router-dom';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import { clearSelectedDate, resetStepsState } from '../schedulerSteps.actions';
import colors from 'utils/colors';

function StepsActionButtons(props) {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.app.activeStep);
  const view = useSelector((state) => state.app.view);
  const { disabled, handleNext, withLoader, isLastStep = false } = props;

  const loading = useSelector((state) => state.schedulerSteps.loading);
  const calendarLoading = useSelector((state) => state.schedulerSteps.calendarLoading);

  const maintenanceLoader = loading || calendarLoading;
  const navigate = useNavigate();

  const handleBack = () => {
    if (activeStep === 4 || isLastStep) {
      dispatch(clearSelectedDate());
    }
    dispatch(setPreviousStep());
  };

  const handleGoHome = () => {
    dispatch(resetStepsState());
    dispatch(resetWelcomeScreenControls());
    dispatch(resetAnnulmentState());
    if (view === 'reschedule') navigate('/reschedule');
    if (view === 'schedule') navigate('/');
  };

  const nextButton = (
    <Button
      color="lightBlue"
      variant="contained"
      sx={{ color: 'white' }}
      disabled={disabled || maintenanceLoader}
      onClick={handleNext}>
      Siguiente
      <ArrowForwardIosIcon
        sx={{ marginLeft: '4px', paddingRight: 0, marginRight: 0, fontSize: '16px' }}
      />
    </Button>
  );

  const loadingButton = (
    <LoadingButton
      loading={loading}
      color="lightBlue"
      variant="contained"
      onClick={handleNext}
      sx={{ color: 'white' }}
      disabled={maintenanceLoader}>
      {activeStep === 4 || isLastStep ? 'Guardar y Agendar' : 'Siguiente'}
    </LoadingButton>
  );

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const actionButton = withLoader ? loadingButton : nextButton;

  return (
    <Box
      sx={{
        ...(!isLastStep && {
          display: 'flex',
          flexDirection: smUp ? 'row-reverse' : 'column',
          justifyContent: isLastStep ? 'space-between' : 'flex-start',
        }),
        position: 'relative',
        textAlign: 'center',
        pt: 5,
      }}>
      {actionButton}
      <Button
        color="lightBlue"
        variant="outlined"
        disabled={maintenanceLoader}
        onClick={activeStep === 0 ? handleGoHome : handleBack}
        sx={{
          ...(smUp ? { mr: 3, mt: 0 } : { mr: 0, mt: 2 }),
          ...(isLastStep && { position: 'absolute', left: 0 }),
        }}>
        <ArrowBackIosIcon sx={{ fontSize: '16px' }} fontSize="small" /> Atrás
      </Button>
    </Box>
  );
}

export default StepsActionButtons;
