import React, { useCallback, useRef, useState } from 'react';

/* Material UI */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

/* Project */
import { controlsChanged, getAppointments } from '../welcomeScreens.actions';
import '../styles.css';
import useForm from 'hooks/useForm';
import { formatRutNew, validatePatent, validateRut } from 'utils/functions';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { darkerGreyOnForms } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import SchedulerStepsStyles from 'content/features/schedulerSteps/SchedulerSteps.styles';

function RescheduleForm({ controls, loading, homeStyles, isMobile }) {
  const radioRef = useRef(null);
  const dispatch = useDispatch();
  const view = useSelector((state) => state.app.view);

  const onChangeText = useCallback(
    (id, value) => {
      let newValue = value;
      if (radioRef.current === 'rut' && id === 'rescheduleSearchValue') {
        newValue = formatRutNew(value);
      }
      dispatch(controlsChanged({ name: id, value: newValue }));
    },
    [radioRef],
  );
  const { onChange, onSubmit, errors, validate } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      rescheduleParameter: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Debe seleccionar una opción ',
        },
      ],
      rescheduleSearchValue: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un valor',
        },
        {
          check: (value) => (radioRef.current === 'rut' ? validateRut(value) : true),
          message: 'RUT inválido',
        },
        {
          check: (value) => (radioRef.current === 'patent' ? validatePatent(value) : true),
          message: 'Patente inválida',
        },
      ],
    },
  });

  const onChangeRadio = useCallback(
    ({ target }) => {
      radioRef.current = target.value;
      const event = { type: 'click', target: { name: 'rescheduleParameter', value: target.value } };
      onChange(event);
      if (controls.rescheduleParameter !== '') {
        validate('rescheduleSearchValue', controls.rescheduleSearchValue);
      }
    },
    [onChange],
  );

  const handleSubmit = () => {
    const { rescheduleParameter, rescheduleSearchValue } = controls;
    onSubmit(() =>
      dispatch(
        getAppointments({
          searchValue: rescheduleSearchValue,
          searchParameter: rescheduleParameter,
          view,
        }),
      ),
    );
  };

  const limitRutField = radioRef.current === 'rut' ? { maxLength: 10 } : {};
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid
        sx={homeStyles.container}
        container
        direction="column"
        lg={6}
        md={8}
        sm={8}
        xs={12}
        // alignItems="center"
        flexWrap="wrap">
        <Grid>
          <FormControl>
            <RadioGroup
              row={!isMobile}
              id="rescheduleParameter"
              name="rescheduleParameter"
              value={controls.rescheduleParameter}
              onChange={onChangeRadio}>
              <FormControlLabel
                id="rescheduleParameter"
                name="rescheduleParameter"
                value="rut"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">RUT</Typography>}
              />
              <FormControlLabel
                id="rescheduleParameter"
                name="rescheduleParameter"
                value="patent"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">Patente</Typography>}
              />
              <FormControlLabel
                id="rescheduleParameter"
                name="rescheduleParameter"
                value="idTicket"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">ID Ticket</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <FormHelperText error={!!errors.rescheduleParameter[0]}>
            {errors.rescheduleParameter[0]}
          </FormHelperText>
        </Grid>
        <Grid sx={SchedulerStepsStyles.searchContainer}>
          <TextField
            color="primary"
            label="Ingresar RUT, patente o ID Ticket"
            InputLabelProps={{
              sx: { ...darkerGreyOnForms },
            }}
            variant="outlined"
            inputProps={{ ...limitRutField }}
            sx={SchedulerStepsStyles.patentField}
            size="small"
            id="rescheduleSearchValue"
            name="rescheduleSearchValue"
            key="rescheduleSearchValue"
            value={controls.rescheduleSearchValue}
            onChange={onChange}
            fullWidth
            error={!!errors.rescheduleSearchValue[0]}
            helperText={errors.rescheduleSearchValue[0]}
          />
          <LoadingButton
            loading={loading}
            color="lightBlue"
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={SchedulerStepsStyles.searchButton}>
            <SearchIcon sx={{ marginRight: '0.5rem', marginLeft: '0.5rem', color: 'white' }} />
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default RescheduleForm;
