import colors from 'utils/colors';

const footerStyles = {
  footer: {
    minHeight: '80px',
    bottom: 0,
    padding: '0px 32px 0px 32px',
    color: '#fff',
    background: '#f4f4f4',
    position: 'relative',
  },
  infoText: {
    color: colors.blue,
    fontSize: (theme) => (theme.breakpoints.down('sm') ? '13px' : '20px'),
  },
};

export default footerStyles;
