/* eslint-disable complexity */
// TODO : Fix complexity log
import React, { useMemo } from 'react';

/* Material UI */
import {
  Grid,
  Typography,
  Paper,
  FormHelperText,
  Button,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

/* Moment */
import moment from 'moment';

/* Project */
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import { selectServiceDates } from '../schedulerSteps.actions';

function SelectTime(props) {
  const {
    date,
    selectHour,
    setSelectHour,
    controls,
    errors,
    calendarLoading,
    reservations,
    technicianSchedule,
  } = props;
  const { selectedTechnician, selectedDate, serviceHours } = controls;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const serviceHour = useMemo(() => {
    // * Formatea la hora que toma el servicio
    let hourSplit;
    if (serviceHours !== '') {
      hourSplit = serviceHours.split('.');
      let hour = Number(hourSplit[0]);
      let minutes = hourSplit[1];

      if (Number(minutes[0]) > 5) {
        hour += 1;
        minutes = 0;
      } else if (Number(minutes[0]) === 5) {
        minutes = Number(minutes[0]);
      } else if (Number(minutes[0]) < 5 && Number(minutes[0]) !== 0) {
        minutes = 5;
      } else if (Number(minutes[0]) === 0) {
        minutes = Number(minutes[0]);
      }
      hourSplit = `${hour}.${minutes}`;
    }

    return hourSplit;
  }, [serviceHours]);

  // console.log('serviceHours:: ', serviceHours)

  const formatedReservations = useMemo(() => {
    // * Asigna un formato a las reservas de los endpoints { fecha, horaInicio, horaTermino }
    let formatData;
    if (reservations) {
      formatData = reservations.map((reservation) => {
        const formaStartDate = moment.utc(reservation.start).toDate();
        const formatEndDate = moment.utc(reservation.end).toDate();

        const dateFormatted = moment(formaStartDate).format('YYYY-MM-DD');
        const start = moment(formaStartDate).format('HH:mm');
        const end = moment(formatEndDate).format('HH:mm');

        return { date: dateFormatted, start, end };
      });
    } else {
      formatData = [];
    }
    return formatData;
  }, [reservations]);

  // console.log('***formatedReservations :', formatedReservations);

  const availableHours = [
    { hour: '08:30', available: true, validatePrev: false, showByDefault: true },
    { hour: '10:00', available: true, validatePrev: false, showByDefault: true },
    { hour: '10:30', available: true, validatePrev: false, showByDefault: false },
    { hour: '11:00', available: true, validatePrev: false, showByDefault: true },
    { hour: '11:30', available: true, validatePrev: false, showByDefault: false },
    { hour: '14:30', available: true, validatePrev: false, showByDefault: true },
    { hour: '15:30', available: true, validatePrev: false, showByDefault: true },
    { hour: '16:00', available: true, validatePrev: false, showByDefault: true },
    { hour: '16:30', available: true, validatePrev: false, showByDefault: true },
    { hour: '17:00', available: true, validatePrev: false, showByDefault: false },
  ];

  const selectHourChange = (hour) => {
    setSelectHour(hour);
    const startHour = hour.split(':')[0];
    const startMinutes = hour.split(':')[1];
    const startHourDate = moment(selectedDate).set({
      hour: Number(startHour),
      minute: Number(startMinutes),
    });
    const endHourAsSeconds = Number(serviceHour) * 3600;
    const endHourDate = moment(startHourDate).add(endHourAsSeconds, 'second');

    const startDate = moment(startHourDate).format('YYYY-MM-DD HH:mm');
    const endDate = moment(endHourDate).format('YYYY-MM-DD HH:mm');

    dispatch(
      selectServiceDates({
        startDate: moment.utc(new Date(startDate)).format(),
        endDate: moment.utc(new Date(endDate)).format(),
      }),
    );
  };

  const selectHoursNotice = (
    <Typography variant="h6" gutterBottom style={{ fontSize: '16px' }}>
      Seleccione una fecha y un técnico para ver los horarios
    </Typography>
  );

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);

  const disableDailyLimitService = useMemo(() => {
    let disabledDates;
    if (selectedService === 'diagnosis') {
      const formatedDate = moment(selectedDate).format('YYYY-MM-DD');
      const filteredReservations = formatedReservations.filter(
        (reservation) => reservation.date === formatedDate,
      );
      // console.log('****filteredReservations :', filteredReservations);
      // console.log('****dailyLimitServices :', Number(selectedTechnician.dailyLimitServices));

      disabledDates = filteredReservations.length >= Number(selectedTechnician.dailyLimitServices);
    } else {
      disabledDates = false;
    }
    // console.log('****disabledDates :', disabledDates);
    return disabledDates;
  }, [selectedService, formatedReservations, selectedDate, selectedTechnician]);

  const filteredReservations = useMemo(() => {
    let formatedReservation;
    if (reservations) {
      const selectedDateFormated = moment(selectedDate).format('YYYY-MM-DD');
      const filteredReservationsAux = reservations.filter(
        (item) => moment.utc(item.start).format('YYYY-MM-DD') === selectedDateFormated,
      );

      formatedReservation = filteredReservationsAux.map((item) => {
        const formatedStartDate = moment.utc(item.start).local().format('YYYY-MM-DD HH:mm');
        const formatedEndDate = moment.utc(item.end).local().format('YYYY-MM-DD HH:mm');

        return { start: formatedStartDate, end: formatedEndDate };
      });
    } else {
      formatedReservation = [];
    }

    return formatedReservation;
  }, [reservations, selectedDate]);

  const formatItemTime = (hour, selectedDateAux) => {
    const availableHourTime = hour.split(':');
    const formatedDate = moment(selectedDateAux)
      .set({
        hour: +availableHourTime[0],
        minute: +availableHourTime[1],
      })
      .format('YYYY-MM-DD HH:mm');
    const endHourService = moment(formatedDate)
      .add(Number(serviceHour) * 3600, 'second')
      .format('YYYY-MM-DD HH:mm');

    return { formatedDate, endHourService };
  };

  const schedulingHours = useMemo(() => {
    // * Validates if an hour is busy or not from reservations
    const busyHours = availableHours.map((item) => {
      const availableHourTime = item.hour.split(':');
      const formatedDate = moment(selectedDate)
        .set({
          hour: +availableHourTime[0],
          minute: +availableHourTime[1],
        })
        .format('YYYY-MM-DD HH:mm');

      const isBusy = [];

      filteredReservations.forEach((reservationItem) => {
        // * Removes one minute to end date to allow reservations in the next block
        const endDate = moment(reservationItem.end)
          .subtract(60, 'seconds')
          .format('YYYY-MM-DD HH:mm');

        if (
          moment(formatedDate).isSame(reservationItem.start) ||
          moment(formatedDate).isSame(endDate)
        ) {
          isBusy.push(true);
        } else if (
          moment(formatedDate).isBetween(reservationItem.start, endDate, undefined, '[]')
        ) {
          isBusy.push(true);
        } else {
          isBusy.push(false);
        }
      });

      const availableType = isBusy.find((busyItem) => busyItem) ? 'not-available' : 'available';
      delete item.available;
      return {
        ...item,
        serviceHour,
        availableType,
        /* serviceHourFinalDate: moment(endDate).format('HH:mm'), */
      };
    });

    // * Adds hours if other hour is not available validation
    const previousHourValidation = busyHours.map(({ hour, availableType, showByDefault }) => {
      let validatUnavailableHour;
      if (hour === '10:30') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '10:00');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      }
      if (hour === '11:30') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '11:00');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      }
      if (hour === '17:00') {
        validatUnavailableHour = busyHours.find((item) => item.hour === '16:30');
        return {
          hour,
          availableType,
          showByDefault: validatUnavailableHour.availableType === 'not-available',
        };
      }
      return {
        hour,
        availableType,
        showByDefault,
      };
    });

    // * Validates past hours conditions
    // TODO: Fix validation when there's multiple reservations in a day (Ej: 24 may)
    const disablePastHours = previousHourValidation.map((item) => {
      const unavailableBlocks = previousHourValidation.filter(
        (phvItem) => phvItem.availableType === 'not-available',
      );

      if (item.availableType !== 'not-available' && !!unavailableBlocks.length) {
        const nextBlockValidation = unavailableBlocks.map((blocks) => {
          const { endHourService } = formatItemTime(item.hour, selectedDate);

          const { formatedDate: unavailableBlockStart, endHourService: unavailableBlockEnd } =
            formatItemTime(blocks.hour, selectedDate);

          let unavailableTime;
          const endDate = moment(endHourService).subtract(60, 'seconds').format('YYYY-MM-DD HH:mm');
          if (
            moment(endDate).isSame(unavailableBlockStart) ||
            moment(endDate).isSame(unavailableBlockEnd)
          ) {
            unavailableTime = true;
          } else
            unavailableTime = moment(endDate).isBetween(
              unavailableBlockStart,
              unavailableBlockEnd,
              undefined,
              '[]',
            );
          return {
            ...item,
            availableType: unavailableTime ? 'not-available' : 'available',
            notByAvailablebyTime: true,
          };
        });

        const filterValidation = nextBlockValidation.filter((nbvItem) => nbvItem !== undefined);
        const errorExist = filterValidation.find(
          (fvItem) => fvItem.availableType === 'not-available',
        );

        return errorExist || item;
      }
      return item;
    });
    return disablePastHours;
  }, [availableHours, selectedDate, filteredReservations, serviceHour]);

  const timeLimitsValidations = (hour, availableType) => {
    let disableHour;
    const startHour = hour.split(':')[0];
    const startMinutes = hour.split(':')[1];
    const startHourService = moment(selectedDate)
      .set({
        hour: startHour,
        minute: startMinutes,
      })
      .add(1, 'minute')
      .format('YYYY-MM-DD HH:mm');

    const endHourService = moment(startHourService)
      .add(Number(serviceHour) * 3600, 'second')
      .subtract(60, 'seconds')
      .format('YYYY-MM-DD HH:mm');

    if (availableType === 'available') {
      technicianSchedule.forEach((schedule) => {
        const day = moment(selectedDate).format('dddd').toLowerCase();
        const scheduleTimes = [];
        // console.log('schedule.scheduleTimes', schedule.scheduleTimes);
        if (schedule.scheduleDays.includes(day)) {
          schedule.scheduleTimes.forEach((time) => {
            const startTimeSchedule = moment(
              `${moment(selectedDate).format('YYYY-MM-DD')} ${time.startTime}`,
            ).local();
            const endTimeSchedule = moment(
              `${moment(selectedDate).format('YYYY-MM-DD')} ${time.endTime}`,
            ).local();

            if (
              moment(startHourService).isBetween(
                startTimeSchedule,
                endTimeSchedule,
                undefined,
                '[]',
              ) &&
              moment(endHourService).isBetween(startTimeSchedule, endTimeSchedule, undefined, '[]')
            ) {
              scheduleTimes.push(true);
            } else {
              scheduleTimes.push(false);
            }
          });
          // check if scheduleTime have all values to false
          disableHour = scheduleTimes.filter((item) => item).length === 0;
        }
      });
    } else {
      disableHour = false;
    }
    return disableHour;
  };

  // const getHourLabel = () => {
  //   if (serviceHour) {
  //     const duration = moment.duration(serviceHour, 'hours');
  //     // console.log('duration', duration._data);
  //     const formatServiceHour = `${duration._data.hours}${
  //       duration._data.minutes === 0 ? ' hrs' : `:${duration._data.minutes} hrs`
  //     }`;
  //
  //     return formatServiceHour;
  //   }
  // };

  const hoursList = (
    <Grid
      container
      sx={{ overflow: 'scroll', overflowX: 'hidden' }}
      style={isMobile ? { height: 'calc(50vh - 100px)' } : {}}>
      {schedulingHours // workHours
        .filter((item) => item.showByDefault === true)
        .map(({ hour, availableType }) => (
          <Grid key={hour} item xs={6} sm={4} md={6} lg={4} sx={SchedulerStepsStyles.hourContainer}>
            <Button
              variant={hour === selectHour ? 'contained' : 'outlined'}
              color={hour === selectHour ? 'lightBlue' : 'primary'}
              size="small"
              disableElevation={hour === selectHour}
              disableRipple={hour === selectHour}
              disableFocusRipple={hour === selectHour}
              disableTouchRipple={hour === selectHour}
              sx={{
                ...(hour === selectHour && { color: 'white' }),
                ...SchedulerStepsStyles.hourTile,
              }}
              onClick={() => selectHourChange(hour)}
              /* disabled={disableUnavailableHours(hour)} */
              disabled={
                availableType === 'not-available' ||
                disableDailyLimitService ||
                timeLimitsValidations(hour, availableType)
              }>
              {hour}
            </Button>
          </Grid>
        ))}
      <Grid item xs={12}>
        <FormHelperText error={!!errors.selectedHour[0]}>{errors.selectedHour[0]}</FormHelperText>
      </Grid>
    </Grid>
  );

  const skeletonHourList = (
    <Grid
      container
      sx={{ overflow: 'scroll', overflowX: 'hidden' }}
      style={isMobile ? { height: 'calc(50vh - 100px)' } : {}}>
      {schedulingHours.map((hour) => (
        <Grid
          key={`${hour.hour}skeleton`}
          item
          xs={4}
          sm={4}
          md={6}
          lg={4}
          sx={SchedulerStepsStyles.hourContainer}>
          <Skeleton variant="rounded" width={100} height={45} />
        </Grid>
      ))}
    </Grid>
  );

  const hours = calendarLoading ? skeletonHourList : hoursList;

  const notSelectedHourContent =
    controls.selectedDate && controls.selectedTechnician !== '' ? hours : selectHoursNotice;

  return (
    <Grid>
      <Paper sx={SchedulerStepsStyles.datePaper}>{notSelectedHourContent}</Paper>
    </Grid>
  );
}

export default SelectTime;
