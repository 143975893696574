const ChevroletLogo = `${window.location.origin}/images/brands/chevrolet-logo.png`;
const NissanLogo = `${window.location.origin}/images/brands/nissan-logo.png`;
const ToyotaLogo = `${window.location.origin}/images/brands/toyota-logo.png`;
const BYDLogo = `${window.location.origin}/images/brands/byd-logo.png`;
const CheryLogo = `${window.location.origin}/images/brands/chery-logo.png`;
const ExeedLogo = `${window.location.origin}/images/brands/exeed-logo.png`;
const fiatLogo = `${window.location.origin}/images/brands/fiat-logo.png`;
const GACLogo = `${window.location.origin}/images/brands/gac-logo.png`;
const JaecooLogo = `${window.location.origin}/images/brands/jaecoo-logo.png`;
const JCMLogo = `${window.location.origin}/images/brands/jcm-logo.png`;
const JeepLogo = `${window.location.origin}/images/brands/jeep-logo.png`;
const MitsubishiLogo = `${window.location.origin}/images/brands/mitsubishi-logo.png`;
const OmodaLogo = `${window.location.origin}/images/brands/omoda-logo.png`;
const RamLogo = `${window.location.origin}/images/brands/ram-logo.png`;
const SsangyongLogo = `${window.location.origin}/images/brands/ssangyong-logo.png`;

export const brandsLogos = {
  Chevrolet: ChevroletLogo,
  Nissan: NissanLogo,
  Toyota: ToyotaLogo,
  BYD: BYDLogo,
  Chery: CheryLogo,
  Exeed: ExeedLogo,
  Fiat: fiatLogo,
  GAC: GACLogo,
  Jaecoo: JaecooLogo,
  JMC: JCMLogo,
  Jeep: JeepLogo,
  Mitsubishi: MitsubishiLogo,
  Omoda: OmodaLogo,
  RAM: RamLogo,
  SSangYong: SsangyongLogo,
};
