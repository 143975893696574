import React, { useCallback, useEffect } from 'react';

/* Material UI */
import { Grid, Typography, Hidden, Snackbar, Alert } from '@mui/material';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* MomentJS */
import moment from 'moment';

/* React Big Calendar */
import { momentLocalizer } from 'react-big-calendar';

/* Project */
import CustomCalendar from 'content/features/calendar/CustomCalendar';
import useForm from 'hooks/useForm';
import { errorsValidation } from 'utils/functions';
import { setActiveStep } from 'content/app.actions';
import Loader from 'content/shared/Loader/Loader';
import SelectTechnician from './components/SelectTechnician';
import SelectTime from './components/SelectTime';
import StepsActionButtons from './components/StepsActionButtons';
import {
  controlsChanged,
  editDateError,
  closeEditDateError,
  emptyDateError,
  closeEmptyDateError,
  getTechniciansAndAvailabilities,
  getAvailabilities,
} from './schedulerSteps.actions';
import LoaderDialog from '../loaders/LoaderDialog';
import ActionsButtonsContainer from './components/ActionsButtonsContainer';

function DateForm() {
  const dispatch = useDispatch();

  const controls = useSelector((state) => state.schedulerSteps.controls);

  const editError = useSelector((state) => state.schedulerSteps.editError);

  const dateError = useSelector((state) => state.schedulerSteps.dateError);

  const loading = useSelector((state) => state.schedulerSteps.loading);

  const calendarLoading = useSelector((state) => state.schedulerSteps.calendarLoading);

  const objects = useSelector((state) => state.app.objects);

  const holidays = useSelector((state) => state.app.objects.holidays);

  const selectedTechnician = useSelector(
    (state) => state.schedulerSteps.controls.selectedTechnician,
  );

  const reservations = useSelector((state) => state.schedulerSteps.controls.reservations);

  const technicianSchedule = useSelector(
    (state) => state.schedulerSteps.controls.technicianSchedule,
  );

  const welcomeScreenControls = useSelector((state) => state.welcomeScreen.controls);

  const { selectedDate, selectedHour, modelId, workshop, serviceType, month, selectedServiceCode } =
    controls;

  const currentDate = moment().startOf('day').toDate();

  const { selectedService: welcomeScreensSelectedService } = welcomeScreenControls;

  const localizer = momentLocalizer(moment);

  const startDate =
    moment(currentDate).format('M') === moment(month).format('M')
      ? moment().startOf('day').utc().format()
      : moment(month).startOf('month').subtract(1, 'days').startOf('day').utc().format();
  const endDate = moment(month).endOf('month').endOf('day').utc().format();

  const selectedService =
    welcomeScreensSelectedService !== '' ? welcomeScreensSelectedService : selectedServiceCode;

  useEffect(() => {
    if (selectedTechnician === '') {
      dispatch(
        getTechniciansAndAvailabilities({
          loading: true,
          selectedService,
          workshopId: workshop,
          modelId,
          kmId: serviceType,
          diagnosisType: serviceType,
          startDate,
          endDate,
        }),
      );
    }
  }, [selectedService, workshop, modelId, serviceType, startDate, endDate, selectedTechnician]);

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const toggleEditError = () => {
    dispatch(editDateError());
  };

  const onCloseEditError = () => {
    dispatch(closeEditDateError());
  };

  const toggleEmptyError = () => {
    dispatch(emptyDateError());
  };

  const onCloseEmptyError = () => {
    dispatch(closeEmptyDateError());
  };

  const selectedDateValidation = (value) => {
    let validation;
    if (value?.toString()?.trim()?.length > 0) {
      onCloseEmptyError();
      validation = true;
    } else {
      toggleEmptyError();
      validation = false;
    }
    return validation;
  };

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      selectedDate: [
        {
          check: (value) => selectedDateValidation(value),
          message: 'Debe seleccionar una fecha posterior al dia de hoy',
        },
      ],
      selectedHour: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe seleccionar una hora',
        },
      ],
      selectedTechnician: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe seleccionar un técnico',
        },
      ],
    },
  });

  const onChangeDate = useCallback(
    (value) => {
      const event = { type: 'click', target: { name: 'selectedDate', value } };
      onChange(event);
    },
    [onChange],
  );

  const onChangeHour = useCallback(
    (value) => {
      const event = { type: 'click', target: { name: 'selectedHour', value } };
      onChange(event);
    },
    [onChange],
  );

  const callAvailabilities = (businessPartnerId) => {
    if (businessPartnerId !== '') {
      const startDate = moment(month).startOf('month').startOf('day').utc().format();
      const endDate = moment(month).endOf('month').endOf('day').utc().format();

      dispatch(
        getAvailabilities({
          calendarLoading: true,
          selectedService,
          startDate,
          endDate,
          technicianBusinessPartnerId: businessPartnerId,
        }),
      );
    }
  };

  const onChangeTechnician = useCallback(
    ({ target }) => {
      onChangeHour('');
      const selectedTechnicianData = {
        type: 'click',
        target: { name: 'selectedTechnician', value: target.value },
      };

      onChange(selectedTechnicianData);
      callAvailabilities(target.value.businessPartnerId);
    },
    [onChange],
  );

  const handleNext = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(setActiveStep()));
  };

  const technicianField = (
    <SelectTechnician
      controls={controls}
      onChange={onChangeTechnician}
      errors={errors}
      objects={objects}
      calendarLoading={calendarLoading}
    />
  );

  const formValid = errorsValidation(errors);

  return (
    <Loader loading={loading} height={300}>
      <form style={{ width: '100%' }} onSubmit={handleNext}>
        <Hidden mdDown>
          <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
            Selecciona técnico
          </Typography>
        </Hidden>
        <Hidden mdDown>{technicianField}</Hidden>
        <Hidden mdUp>{technicianField}</Hidden>

        <Hidden mdUp>
          <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
            SELECCIONAR HORARIO
          </Typography>
        </Hidden>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12} sm={12} md={6} boxSizing="border-box" pr={2}>
            <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
              Selecciona fecha
            </Typography>
            <CustomCalendar
              date={selectedDate}
              setDate={onChangeDate}
              currentDate={currentDate}
              controls={controls}
              setSelectHour={onChangeHour}
              month={month}
              calendarLoading={calendarLoading}
              holidays={holidays}
              reservations={reservations}
              technicianSchedule={technicianSchedule}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} pl={2} boxSizing="border-box">
            <Grid>
              <Hidden mdDown>
                <Typography color="primary" variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
                  Horarios disponibles
                </Typography>
              </Hidden>
              <SelectTime
                date={selectedDate}
                selectHour={selectedHour}
                setSelectHour={onChangeHour}
                controls={controls}
                errors={errors}
                calendarLoading={calendarLoading}
                reservations={reservations}
                technicianSchedule={technicianSchedule}
              />
            </Grid>
          </Grid>

          <Snackbar
            open={dateError}
            onClose={onCloseEmptyError}
            autoHideDuration={2800}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              {errors.selectedDate[0]}
            </Alert>
          </Snackbar>
          <Snackbar
            open={editError}
            onClose={onCloseEditError}
            autoHideDuration={2800}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Para cambiar la fecha presioné el botón editar en la fecha seleccionada.
            </Alert>
          </Snackbar>
        </Grid>
        <ActionsButtonsContainer>
          <StepsActionButtons handleNext={handleNext} disabled={formValid} />
        </ActionsButtonsContainer>
      </form>
      <LoaderDialog open={calendarLoading} title="Buscando reservas técnico" />
    </Loader>
  );
}

export default DateForm;
