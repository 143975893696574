import React from 'react';

/* React Redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Grid, FormHelperText } from '@mui/material';

/* Project */
import ServiceForm from './ServiceForm';
import ServiceImageSection from './ServiceImageSection';

function SelectService(props) {
  const { submitButtonRef } = props;

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);

  return (
    <Grid mt={7} container direction="column">
      <ServiceForm submitButtonRef={submitButtonRef} />
      <Grid item xs={12} sm={12} md={6}>
        <FormHelperText>
          NOTA: En caso de servicios adicionales aumentará el tiempo de revisión del vehículo.
        </FormHelperText>
      </Grid>
    </Grid>
  );
}

export default SelectService;
