import React, { useRef } from 'react';

/* React redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Grid, Typography } from '@mui/material';

/* Project */
import textImageValidationSelectedService from '../../shared/textImageValidationSelectedService';
import SelectService from './components/SelectService';
import StepsActionButtons from './components/StepsActionButtons';
import ActionsButtonsContainer from './components/ActionsButtonsContainer';

function ServiceForm() {
  const submitButtonRef = useRef(null);

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);
  const activeStep = useSelector((state) => state.app.activeStep);

  const { textValidation } = textImageValidationSelectedService[selectedService];

  const handleNext = () => {
    window.history.replaceState({}, '', `/schedule/steps/${activeStep}`);
    /*
    window.gtag("event", "button_click", {
      category: "schedule",
      action: "Click",
      label: "Step",
      value: activeStep,
    });
    */
    submitButtonRef.current.click();
  };

  return (
    <>
      <SelectService submitButtonRef={submitButtonRef} />
      <ActionsButtonsContainer>
        <StepsActionButtons handleNext={handleNext} />
      </ActionsButtonsContainer>
    </>
  );
}

export default ServiceForm;
