import React from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/* Project */
import { Link } from '@mui/material';
import { Email, PhoneEnabled } from '@mui/icons-material';
import footerStyles from './footer.styles';
import colors from 'utils/colors';

function Footer() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={footerStyles.footer}>
      <Grid
        flexGrow={2}
        sx={{ width: '100%', borderBottom: `1px solid ${colors.blue}` }}
        alignItems="center"
      />
      <Grid
        flexGrow={1}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between">
        <Grid alignItems="center">
          <Typography color="primary" variant="subtitle2">
            Salfa | Todos los derechos reservados SALFA
          </Typography>
        </Grid>
        <Grid mt={1} display="flex" flexDirection="row" gap={2} justifyContent="center">
          <Typography variant="subtitle2" sx={footerStyles.infoText}>
            Términos y Condiciones
          </Typography>
          <Typography variant="subtitle2" sx={footerStyles.infoText}>
            Política de Cookies
          </Typography>
          <Typography variant="subtitle2" sx={footerStyles.infoText}>
            Política de Privacidad
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
