const homeStyles = {
  container: {
    marginTop: '20px',
  },
  searchContainer: {
    alignItems: 'flex-start',
    justifyContent: (theme) => (theme.breakpoints.down('sm') ? 'space-between' : 'flex-start'),
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '40px',
    marginBottom: '20px',
  },
  radio: {
    color: '#002855',
    '&.Mui-checked': {
      color: '#002855',
    },
  },
  field: {
    color: 'red',
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '1rem',
    marginTop: '20px',
  },
  serviceCard: {
    flexGrow: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedServiceCard: {
    border: '5px solid #002855',
    opacity: '.8',
  },
};

export default homeStyles;
