import React from 'react';

/* Material UI */
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
  Hidden,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

/* Project */
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import colors from 'utils/colors';

function ResumeCard(props) {
  const { item, activePanel, setActivePanel } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const phoneSize = useMediaQuery(theme.breakpoints.down('sm'));
  const icons = [
    {
      name: 'DirectionsCarFilledIcon',
      icon: <DirectionsCarFilledIcon color="primary" sx={{ marginRight: '5px' }} />,
    },
    {
      name: 'CalendarTodayIcon',
      icon: <CalendarTodayIcon color="primary" sx={{ marginRight: '5px' }} />,
    },
    {
      name: 'AccountCircleIcon',
      icon: <AccountCircleIcon color="primary" sx={{ marginRight: '5px' }} />,
    },
    {
      name: 'BuildOutlinedIcon',
      icon: <BuildOutlinedIcon color="primary" sx={{ marginRight: '5px' }} />,
    },
    {
      name: 'InsertDriveFileOutlinedIcon',
      icon: <InsertDriveFileOutlinedIcon color="primary" sx={{ marginRight: '5px' }} />,
    },
  ];

  const summary = (
    <AccordionSummary expandIcon={isMobile && <ExpandMoreIcon />}>
      <Grid container direction="row" alignItems="center">
        {icons
          .filter((icon) => icon.name === item.icon)
          .map(({ icon, name }) => (
            <span key={name}>{icon}</span>
          ))}
        <Grid item xs={10}>
          <Typography variant="h6" color="primary">
            {item.title}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );

  const details = (
    <AccordionDetails>
      <Grid container direction="row">
        <Grid item xs={12} sx={phoneSize ? { marginLeft: '10px' } : { marginLeft: '50px' }}>
          <Grid container>
            {item?.content.map((i) =>
              i.label === 'Notas' ? (
                <Grid
                  key={i.label + i.value}
                  item
                  xs={12}
                  sx={SchedulerStepsStyles.resumeLabelContainer}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'bold' }}>
                      {i.label || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" sx={{ paddingLeft: '10px', color: 'gray' }}>
                      {i.value || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  key={i.label + i.value}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={SchedulerStepsStyles.resumeLabelContainer}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'bold' }}>
                      {i.label || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" sx={{ paddingLeft: '10px', color: 'gray' }}>
                      {i.value || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails>
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ marginBottom: '20px' }}>
      <Hidden lgDown>
        <Grid sx={{ minHeight: '100%', width: '95%' }}>
          {summary}
          <Grid sx={{ border: `2px solid ${colors.lightBlue}`, borderRadius: '8px' }}>
            {details}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Accordion
          expanded={activePanel === item.title}
          onChange={() =>
            activePanel === item.title ? setActivePanel('') : setActivePanel(item.title)
          }>
          {summary}
          {details}
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            12341234
          </Typography>
        </Accordion>
      </Hidden>
    </Grid>
  );
}

export default ResumeCard;
