import React from 'react';

/* React redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { Button, Typography } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import colors from 'utils/colors';

function ContactButtons(props) {
  const { toggleCreateDialog } = props;
  const disableCreateContact = useSelector(
    (state) => state.schedulerSteps.controls.disableCreateContact,
  );

  return (
    <Button
      color="primary"
      variant="outlined"
      size="medium"
      sx={{ boxShadow: 3, height: '30px', borderWidth: '2px', borderColor: colors.blue }}
      onClick={toggleCreateDialog}
      disabled={disableCreateContact}>
      <Typography mr={1}>Agregar Contacto</Typography>
      <PersonAddAlt1Icon color="primary" />
    </Button>
  );
}

export default ContactButtons;
