/* Redux */
import { createReducer } from '@reduxjs/toolkit';

/* Project */
import {
  closeNoRecordsError,
  controlsChanged,
  getAppointments,
  getAppointmentsSuccess,
  resetWelcomeScreenControls,
  toggleNoRecordsError,
} from './welcomeScreens.actions';

const initialState = {
  controls: {
    view: '',
    selectedService: '',
    preSelectedService: 'scheduling',
    annulmentParameter: '',
    annulmentSearchValue: '',
    rescheduleParameter: '',
    rescheduleSearchValue: '',
  },
  loading: false,
  appointments: [],
  appointmentsTitle: '',
  error: false,
  errorType: '',
};

const WelcomeScreenReducer = createReducer(initialState, (builder) => {
  builder.addCase(controlsChanged, (state, action) => {
    state.controls = {
      ...state.controls,
      ...action.payload,
    };
  });
  builder.addCase(getAppointments, (state) => {
    state.loading = true;
  });
  builder.addCase(getAppointmentsSuccess, (state, { payload }) => {
    state.loading = false;
    state.appointments = payload.appointments;
    state.appointmentsTitle = payload.title;
  });
  builder.addCase(resetWelcomeScreenControls, (state) => {
    state.loading = false;
    state.controls = initialState.controls;
    state.appointments = [];
    state.appointmentsTitle = '';
    state.error = false;
    state.errorType = '';
  });
  builder.addCase(toggleNoRecordsError, (state) => {
    state.loading = false;
    state.error = true;
    state.errorType = 'no-records';
  });
  builder.addCase(closeNoRecordsError, (state) => {
    state.loading = false;
    state.error = false;
    state.errorType = '';
  });
});

export default WelcomeScreenReducer;
