/* Redux */
import { createReducer } from '@reduxjs/toolkit';

/* MomentJS */
import moment from 'moment';

/* Project */
import {
  addUserContact,
  clearContact,
  clearControlsErrors,
  clearDate,
  clearOwnerVehicleFormValues,
  clearScheduleData,
  clearSchedulerError,
  clearSelectedDate,
  clearTechnician,
  clearVehicleFormValues,
  closeAppointmentInfo,
  closeContactDialog,
  closeEditDateError,
  closeEmptyDateError,
  closeInternalError,
  controlsChanged,
  editDateError,
  emptyDateError,
  getAvailabilities,
  getAvailabilitiesSuccess,
  getDates,
  getDatesSuccess,
  getSchedulerResponse,
  getTechniciansAndAvailabilities,
  getTechniciansAndAvailabilitiesSuccess,
  getUserData,
  getUserDataSuccess,
  getUserVehicle,
  getUserVehicleSuccess,
  getUserVehicleWithConectaApi,
  getUserVehicleWithConectaApiSuccess,
  onChangeImage,
  openAppointmentInfo,
  removeContact,
  resetIsFindedUser,
  resetIsFindedVehicle,
  resetStepsState,
  schedulerInternalError,
  selectContact,
  selectReschedule,
  selectServiceDates,
  setControlsErrors,
  setMonth,
  setOpenNotEmailContactDialog,
  setTechnicians,
  submitReSchedule,
  submitSchedule,
  submitScheduleSuccess,
  toggleAddContact,
  toggleContactDialog,
  toggleEdit,
  toggleSchedulerError,
  toggleTermsAndConditions,
  toggleTermsErrors,
  updateContact,
} from './schedulerSteps.actions';

const initialState = {
  controls: {
    disableForm: true,
    findButtonUsed: false,
    patent: '',
    brand: '',
    brandId: '',
    brandExternalId: '',
    vin: '',
    modelId: '',
    versionId: '',
    year: '',
    serviceKm: '',
    serviceType: '',
    serviceNotes: '',
    region: '',
    workshop: '',
    rut: '',
    name: '',
    lastName: '',
    phone: '',
    confirmPhone: '',
    email: '',
    confirmEmail: '',
    contactName: '',
    contactLastName: '',
    contactPhone: '',
    contactEmail: '',
    selectedDate: '',
    selectedHour: '',
    selectedStartDate: '',
    selectedEndDate: '',
    selectedTechnician: '',
    acceptTermsandConditions: false,
    contacts: [],
    contactId: '',
    selectedContact: '',
    selectedIndexContact: -1,
    selectedReschedule: {},
    technicians: [],
    successData: null,
    disableCreateContact: false,
    month: moment(),
    reservations: [],
    technicianSchedule: [],
    serviceSkills: [],
    serviceHours: '',
    genericProduct: '',
    partyId: '',
    vehicleExternalId: '', // TODO: SET DATA
    clientId: '',
    skillBrand: '',
    skillService: '',
    appointments: [],
    selectedServiceCode: '',
    appointmentInfo: false,
  },
  controlsErrors: {
    patent: '',
    brandExternalId: '',
    vin: '',
    modelId: '',
    versionId: '',
    year: '',
    selectedContact: '',
  },
  addContact: false,
  openContactDialog: false,
  openNotEmailContactDialog: false,
  isEdit: false,
  dateError: false,
  editError: false,
  termsError: false,
  loading: false,
  calendarLoading: false,
  error: false,
  errorMsg: '',
  isFindedVehicle: false,
  isFindedUser: false,
  requestError: false,
  errorType: '',
  dateTakenError: false,
  createdSchedulingCode: '',
  schedulingResponse: {
    ticketId: '',
    schedulingCode: '',
    date: '',
    address: '',
    userName: '',
    technicianName: '',
    slotsError: false,
    internalError: false,
    redirectSuccessScreen: false,
  },
};

const SchedulerStepsReducer = createReducer(initialState, (builder) => {
  builder.addCase(controlsChanged, (state, action) => {
    state.controls = {
      ...state.controls,
      ...action.payload,
    };
  });

  builder.addCase(toggleContactDialog, (state) => {
    state.openContactDialog = !state.openContactDialog;
    state.controls.selectedContact = '';
    state.controls.contactName = '';
    state.controls.contactLastName = '';
    state.controls.contactPhone = '';
    state.controls.contactEmail = '';
    state.controls.selectedContact = '';
    state.controls.selectedIndexContact = -1;
  });
  builder.addCase(toggleAddContact, (state) => {
    state.addContact = !state.addContact;
    state.isEdit = false;
    state.controls.selectedContact = '';
  });
  builder.addCase(toggleEdit, (state) => {
    state.openContactDialog = !state.openContactDialog;
    state.isEdit = true;
  });
  builder.addCase(selectContact, (state, action) => {
    state.controls.selectedContact = action.payload.contact.email;
    state.controls.contactName = action.payload.contact.name;
    state.controls.contactLastName = action.payload.contact.lastName;
    state.controls.contactPhone = action.payload.contact.phone;
    state.controls.contactEmail = action.payload.contact.email;
    state.controls.contactId = action.payload.contact.externalId;
    state.controls.selectedIndexContact = action.payload.selectedIndexContact;
  });
  builder.addCase(closeContactDialog, (state) => {
    state.openContactDialog = false;
    state.isEdit = false;
  });
  builder.addCase(clearContact, (state) => {
    /* state.controls.selectedContact = ''; */
    state.controls.contactName = '';
    state.controls.contactLastName = '';
    state.controls.contactPhone = '';
    state.controls.contactEmail = '';
    state.controls.selectedIndexContact = -1;
    state.controls.contactId = '';
    state.controls.selectedContact = '';
    /* state.controls.selectedIndexContact = -1; */
  });
  builder.addCase(removeContact, (state, { payload }) => {
    state.controls.contacts = payload;
    state.controls.disableCreateContact = false;
  });
  builder.addCase(editDateError, (state) => {
    state.editError = true;
  });
  builder.addCase(closeEditDateError, (state) => {
    state.editError = false;
  });
  builder.addCase(emptyDateError, (state) => {
    state.dateError = true;
  });
  builder.addCase(closeEmptyDateError, (state) => {
    state.dateError = false;
  });
  builder.addCase(toggleTermsAndConditions, (state) => {
    state.controls.acceptTermsandConditions = !state.controls.acceptTermsandConditions;
  });
  builder.addCase(toggleTermsErrors, (state) => {
    state.termsError = true;
  });
  builder.addCase(addUserContact, (state, { payload }) => {
    state.controls.contacts = [...state.controls.contacts, ...payload];
    state.controls.selectedContact = '';
    state.controls.disableCreateContact = true;
  });
  builder.addCase(selectReschedule, (state, { payload }) => {
    // console.log('payload :', payload);
    state.controls.selectedReschedule = payload;
    state.controls.workshop = payload.workshopId;
    state.controls.modelId = payload.modelId;
    state.controls.serviceType = payload.serviceTypeId;
    state.controls.selectedServiceCode = payload.selectedService;
    state.controls.region = payload.regionId;
    state.controls.brand = payload.brand;
    state.controls.brandExternalId = payload.brandId;
    state.controls.vin = payload.vehicleSerialId;
    state.controls.year = payload.vehicleYear;
    state.controls.patent = payload.patent;
    state.controls.rut = payload.clientRut;
    state.controls.serviceKm = payload.km;
    state.controls.name = payload.clientName;
    state.controls.lastName = payload.clientLastname;
    state.controls.email = payload.clientEmail;
    state.controls.phone = payload.clientPhone;
    state.createdSchedulingCode = payload.onlineCode;
  });
  builder.addCase(getUserVehicle, (state) => {
    state.loading = true;
  });
  builder.addCase(getUserVehicleSuccess, (state, { payload }) => {
    state.loading = false;
    state.controls.brand = 'chevrolet';
    state.controls.vin = payload.userVehicle.vin;
    state.controls.modelId = payload.userVehicle.modelId;
    state.controls.versionId = payload.userVehicle.versionId;
    state.controls.year = payload.userVehicle.year;
    state.controls.name = payload.client.name;
    state.controls.lastName = payload.client.lastName;
    state.controls.phone = payload.client.phone;
    state.controls.confirmPhone = payload.client.phone;
    state.controls.email = payload.client.email;
    state.controls.confirmEmail = payload.client.email;
    state.controls.rut = payload.client.rut;
    state.controls.contacts = payload.contacts;
    state.isFindedVehicle = true;
    state.isFindedUser = true;
  });
  builder.addCase(getUserData, (state) => {
    state.loading = true;
    state.controls.disableForm = true;
  });
  builder.addCase(getUserDataSuccess, (state, { payload }) => {
    state.loading = false;
    if (!payload.user.lastName || payload.user.lastName.trim() === '') {
      const fullNameParts = payload.user.name.split(' ');
      state.controls.name = fullNameParts.shift();
      state.controls.lastName = fullNameParts.join(' ');
    } else {
      state.controls.name = payload.user.name;
      state.controls.lastName = payload.user.lastName;
    }
    state.controls.phone = payload.user.phone;
    state.controls.confirmPhone = payload.user.phone;
    state.controls.email = payload.user.email;
    state.controls.confirmEmail = payload.user.email;
    state.controls.contacts = [...state.controls.contacts, ...payload.userContact];
    state.controls.clientId = payload.user.clientId;
    state.isFindedUser = true;
    state.controls.findButtonUsed = true;
  });
  builder.addCase(getDates, (state) => {
    state.loading = true;
  });
  builder.addCase(getDatesSuccess, (state, { payload }) => {
    state.loading = false;
    state.controls.technicians = payload.technicians;
  });
  builder.addCase(submitSchedule, (state) => {
    state.loading = true;
  });
  builder.addCase(submitScheduleSuccess, (state, { payload }) => {
    state.loading = false;
    state.controls.successData = payload.successData;
  });
  builder.addCase(toggleSchedulerError, (state, { payload }) => {
    state.loading = false;
    state.calendarLoading = false;
    state.error = true;
    state.errorMsg = payload.errorMsg;
  });
  builder.addCase(clearSchedulerError, (state) => {
    state.error = false;
    state.errorMsg = '';
  });
  builder.addCase(resetIsFindedVehicle, (state, { payload }) => {
    state.isFindedVehicle = payload.isFindedVehicle;
  });
  builder.addCase(resetIsFindedUser, (state, { payload }) => {
    state.isFindedUser = payload.isFindedUser;
  });
  builder.addCase(setTechnicians, (state, { payload }) => {
    state.controls.technicians = payload.technicians;
  });
  builder.addCase(clearVehicleFormValues, (state) => {
    state.controls.brand = '';
    state.controls.brandId = '';
    state.controls.brandExternalId = '';
    state.controls.patent = '';
    state.controls.vin = '';
    state.controls.modelId = '';
    state.controls.versionId = '';
    state.controls.year = '';
    state.controls.name = '';
    state.controls.lastName = '';
    state.controls.phone = '';
    state.controls.confirmPhone = '';
    state.controls.email = '';
    state.controls.confirmEmail = '';
    state.controls.rut = '';
    state.controls.contacts = [];
    state.isFindedVehicle = false;
    state.isFindedUser = false;
  });
  builder.addCase(clearOwnerVehicleFormValues, (state) => {
    state.controls.name = '';
    state.controls.lastName = '';
    state.controls.phone = '';
    state.controls.confirmPhone = '';
    state.controls.email = '';
    state.controls.confirmEmail = '';
    state.controls.rut = '';
    state.controls.contacts = [];
    state.isFindedUser = false;
    state.addContact = false;
    state.controls.disableCreateContact = false;
    state.controls.findButtonUsed = false;
    state.controls.disableForm = true;
  });
  builder.addCase(getUserVehicleWithConectaApi, (state) => {
    /* state.loading = false;
    state.isFindedVehicle = true;
    state.isFindedUser = true; */
    state.loading = true;
  });
  builder.addCase(getUserVehicleWithConectaApiSuccess, (state, { payload }) => {
    state.loading = false;
    state.isFindedVehicle = true;
    state.controls.vin = payload.userVehicle.vin;
    state.controls.vehicleExternalId = payload.userVehicle.externalId;
  });
  builder.addCase(onChangeImage, (state, { payload }) => {
    state.controls.brandId = payload.id;
    state.controls.brand = payload.name; // THIS
    state.controls.brandExternalId = payload.externalId;
    state.controls.modelId = '';
  });
  builder.addCase(setOpenNotEmailContactDialog, (state, { payload }) => {
    state.openNotEmailContactDialog = payload.openNotEmailContactDialog;
    state.controls.selectedIndexContact = payload.selectedIndexContact;
  });
  builder.addCase(updateContact, (state, { payload }) => {
    state.controls.contacts[payload.selectedIndexContact] = {
      name: payload.contactValues.contactName,
      lastName: payload.contactValues.contactLastName,
      phone: payload.contactValues.contactPhone,
      email: payload.contactValues.contactEmail,
    };
    /* state.controls.selectedContact = payload.contactValues.contactEmail;
    state.controls.selectedIndexContact = payload.selectedIndexContact; */
  });
  builder.addCase(getTechniciansAndAvailabilities, (state, { payload }) => {
    state.loading = payload.loading;
    state.calendarLoading = payload.calendarLoading;
  });
  builder.addCase(getTechniciansAndAvailabilitiesSuccess, (state, { payload }) => {
    state.loading = false;
    state.calendarLoading = false;
    state.controls.technicians = payload.technicians;
    state.controls.reservations = payload.reservations;
    state.controls.serviceSkills = payload.serviceSkills.skills;
    state.controls.serviceHours = payload.serviceSkills.serviceHours;
    state.controls.genericProduct = payload.serviceSkills.genericProduct;
    state.controls.skillBrand = payload.serviceSkills.skillBrand;
    state.controls.skillService = payload.serviceSkills.skillService;
  });
  builder.addCase(setControlsErrors, (state, { payload }) => {
    state.controlsErrors = {
      ...state.controlsErrors,
      ...payload.controlsErrors,
    };
  });
  builder.addCase(clearControlsErrors, (state) => {
    state.controlsErrors = {
      ...state.controlsErrors,
      ...initialState.controlsErrors,
    };
  });
  builder.addCase(setMonth, (state, { payload }) => {
    state.controls.month = payload.month;
  });
  builder.addCase(resetStepsState, (state) => {
    state.controls = initialState.controls;
    state.controlsErrors = initialState.controlsErrors;
    state.addContact = false;
    state.openContactDialog = false;
    state.openNotEmailContactDialog = false;
    state.isEdit = false;
    state.dateError = false;
    state.editError = false;
    state.termsError = false;
    state.loading = false;
    state.calendarLoading = false;
    state.error = false;
    state.errorMsg = '';
    state.isFindedVehicle = false;
    state.isFindedUser = false;
    state.requestError = false;
    state.errorType = '';
    state.schedulingResponse = initialState.schedulingResponse;
  });
  builder.addCase(getAvailabilities, (state, { payload }) => {
    state.loading = payload.loading;
    state.calendarLoading = payload.calendarLoading;
    state.controls.reservations = [];
    state.controls.technicianSchedule = [];
  });
  builder.addCase(getAvailabilitiesSuccess, (state, { payload }) => {
    state.loading = false;
    state.calendarLoading = false;
    state.controls.reservations = payload.reservations;
    state.controls.technicianSchedule = payload.technicianSchedule;
  });
  builder.addCase(clearSelectedDate, (state) => {
    state.controls.selectedDate = '';
    state.controls.selectedHour = '';
    state.controls.selectedTechnician = '';
    state.controls.selectedStartDate = '';
    state.controls.selectedEndDate = '';
  });
  builder.addCase(selectServiceDates, (state, { payload }) => {
    state.controls.selectedStartDate = payload.startDate;
    state.controls.selectedEndDate = payload.endDate;
  });
  builder.addCase(getSchedulerResponse, (state, { payload }) => {
    state.loading = false;
    state.schedulingResponse.ticketId = payload.schedulingResponseData.ticketId;
    state.schedulingResponse.date = payload.schedulingResponseData.date;
    state.schedulingResponse.address = payload.schedulingResponseData.address;
    state.schedulingResponse.userName = payload.schedulingResponseData.userName;
    state.schedulingResponse.technicianName = payload.schedulingResponseData.technicianName;
    state.schedulingResponse.redirectSuccessScreen =
      payload.schedulingResponseData.redirectSuccessScreen;
    state.requestError = payload.schedulingResponseData.slotsError;
    state.errorType = payload.schedulingResponseData.slotsError ? 'date-taken' : '';
    state.schedulingResponse.slotsError = payload.schedulingResponseData.slotsError;
    state.schedulingResponse.schedulingCode = payload.schedulingResponseData.schedulingCode;
    state.dateTakenError = payload.schedulingResponseData.slotsError;
    state.createdSchedulingCode = payload.schedulingResponseData.schedulingCode;
    // Store created client and contact ids in order to avoid creating duplicate records when the user is not registered
    state.controls.clientId = payload.clientId;
    state.controls.contactId = payload.contactId;
  });
  builder.addCase(schedulerInternalError, (state, { payload }) => {
    state.loading = false;
    state.requestError = payload.error;
    state.errorType = payload.errorType;
  });
  builder.addCase(closeInternalError, (state) => {
    state.loading = false;
    state.requestError = false;
    state.errorType = '';
  });
  builder.addCase(clearScheduleData, (state) => {
    state.controls.selectedTechnician = '';
    state.controls.selectedDate = '';
    state.controls.selectedHour = '';
    state.schedulingResponse = { ...initialState.schedulingResponse };
    state.requestError = false;
    state.errorType = '';
  });
  builder.addCase(submitReSchedule, (state) => {
    state.loading = true;
  });
  builder.addCase(clearDate, (state) => {
    state.controls.selectedDate = '';
  });
  builder.addCase(openAppointmentInfo, (state, { payload }) => {
    // console.log('payload :', payload);
    state.controls.appointmentInfo = payload;
  });
  builder.addCase(closeAppointmentInfo, (state) => {
    state.controls.appointmentInfo = false;
  });
  builder.addCase(clearTechnician, (state) => {
    state.controls.selectedTechnician = '';
    state.controls.reservations = [];
    state.controls.technicianSchedule = [];
  });
});

export default SchedulerStepsReducer;
