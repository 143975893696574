import React, { useCallback } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Box, Grid, Typography } from '@mui/material';

/* Project */
import useForm from 'hooks/useForm';
import { setActiveStep } from 'content/app.actions';
import { controlsChanged, selectReschedule } from './schedulerSteps.actions';
import ResultsTable from './components/ResultsTable';
import InfoAlert from './components/InfoAlert';
import StepsActionButtons from './components/StepsActionButtons';
import ActionsButtonsContainer from './components/ActionsButtonsContainer';

function SelectReschedule() {
  const dispatch = useDispatch();
  const controls = useSelector((state) => state.schedulerSteps.controls);
  const { name, rut } = useSelector((state) => state.schedulerSteps.controls);

  const onChangeText = useCallback((id, value) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const { onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      selectedReschedule: [
        {
          check: (value) => Object.keys(value).length > 0,
          message: 'Debe seleccionar una cita para reagendar',
        },
      ],
    },
  });

  const handleChangeTable = (value) => {
    dispatch(selectReschedule(value));
  };

  const handleNext = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(setActiveStep()));
  };

  return (
    <Grid>
      <form onSubmit={handleNext}>
        <Grid sx={{ marginBottom: '20px' }}>
          <Grid display="flex" flexDirection="row" justifyContent="flex-start" gap={4}>
            <Box display="flex" gap={1}>
              <Typography color="primary" variant="body1" fontWeight="bold">
                Cliente:
              </Typography>
              <Typography variant="body1" color="primary">
                {name}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="body1" color="primary" fontWeight="bold">
                Rut:
              </Typography>
              <Typography variant="body1" color="primary">
                {rut}
              </Typography>
            </Box>
          </Grid>
          <ResultsTable onChange={handleChangeTable} controls={controls} errors={errors} />
        </Grid>
        <Grid sx={{ marginBottom: '20px' }}>
          <InfoAlert controls={controls} />
        </Grid>
        <ActionsButtonsContainer>
          <StepsActionButtons
            handleNext={handleNext}
            disabled={controls.selectedReschedule.length > 1}
          />
        </ActionsButtonsContainer>
      </form>
    </Grid>
  );
}

export default SelectReschedule;
