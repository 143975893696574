import React, { useMemo, useState } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/* Project */
import { onChangeImage, controlsChanged, setControlsErrors } from '../schedulerSteps.actions';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import InformationDialog from './InformationDialog';
import { brandsLogos } from '../data/brandsLogos';
import CustomAutoComplete from './CustomAutoComplete';

function BrandsCards() {
  const [openDialog, setopenDialog] = useState(false); // TODO: DELETE STATE AND USE REDUX STORE
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const brands = useSelector((state) => state.app.objects.brands);
  const brandExternalId = useSelector((state) => state.schedulerSteps.controls.brandExternalId);
  const brandId = useSelector((state) => state.schedulerSteps.controls.brandId);
  const brandIdError = useSelector((state) => state.schedulerSteps.controlsErrors.brandId);

  const brandsData = useMemo(() => Object.keys(brands).map((key) => brands[key]), [brands]);
  const brandValidation = (brand) =>
    brandsData.every((item) => item.name !== brand) ? 'Debe seleccionar una marca' : '';

  const onChange = (brand) => {
    if (!brand) {
      dispatch(onChangeImage({ id: '', name: '', externalId: '' }));
      return;
    }
    const errorMessage = brandValidation(brand.name);
    dispatch(
      setControlsErrors({
        controlsErrors: {
          brandId: errorMessage,
        },
      }),
    );
    dispatch(onChangeImage(brand));
  };

  const onClickOthers = () => {
    dispatch(controlsChanged({ name: 'brand', value: 'others' }));
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
    dispatch(controlsChanged({ name: 'brand', value: '' }));
  };

  if (isMobile) {
    return (
      <Grid>
        <FormControl fullWidth>
          <CustomAutoComplete
            name="brandId"
            value={brandId.toString()}
            options={brandsData}
            disabled={false}
            size="small"
            label="Seleccionar marca"
            error={!!brandIdError}
            getNewValue={(brand) =>
              onChange(brandsData.find((item) => item.id === brand.id))?.name || ''
            }
          />
          <FormHelperText error={!!brandIdError}>{brandIdError}</FormHelperText>
        </FormControl>
      </Grid>
    );
  }

  return (
    <>
      <Grid sx={SchedulerStepsStyles.brandsContainer}>
        {brandsData
          .filter((brand) => brandsLogos[brand.name])
          .map((brand) => (
            <Paper
              key={brand.externalId}
              sx={
                brandExternalId === brand.externalId
                  ? SchedulerStepsStyles.brandPaperSelected
                  : SchedulerStepsStyles.brandPaper
              }
              onClick={() => onChange(brand)}>
              <Grid>
                <img alt={`${brand.name}-logo`} src={brandsLogos[brand.name]} width="80px" />
              </Grid>
            </Paper>
          ))}

        <Paper sx={SchedulerStepsStyles.brandPaper} onClick={onClickOthers}>
          <MoreHorizIcon sx={SchedulerStepsStyles.moreIcon} />
          <Typography variant="body2" sx={{ color: '#999' }} gutterBottom>
            Otros
          </Typography>
        </Paper>
      </Grid>
      {!!brandIdError && (
        <FormHelperText sx={{ mt: 2 }} error={!!brandIdError}>
          Debe seleccionar una marca
        </FormHelperText>
      )}
      <InformationDialog open={openDialog} handleClose={handleCloseDialog} type="otherBrand" />
    </>
  );
}

export default BrandsCards;
