/* eslint-disable */
import * as React from 'react';

/* React Redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

/* Project */
import StepsMobile from './StepsMobile';
import colors from 'utils/colors';
import stepsIcons, { labelsIcons, labelsIcons2 } from './stepsIcons';

const StepsConnector = styled(StepConnector)(({}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.lightBlue,
      opacity: 1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.lightBlue,
      opacity: 1,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.lightBlue,
    opacity: 0.5,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function Steps(props) {
  const { stepsNumber, children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const steps = stepsIcons[stepsNumber];
  const activeStep = useSelector((state) => state.app.activeStep);

  return (
    <Box sx={{ width: '100%', padding: 0, position: 'relative' }}>
      <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 0 }}>
        <Grid item xs={12} sm={12} md={7} lg={6}>
          {isMobile ? (
            <StepsMobile steps={steps} activeStep={activeStep} />
          ) : (
            <Stepper activeStep={activeStep} connector={<StepsConnector />} alternativeLabel>
              {steps.map((label, i) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step sx={{ padding: 0 }} key={label} {...stepProps}>
                    <StepLabel
                      StepIconProps={{ active: activeStep >= i }}
                      sx={{ padding: 0 }}
                      StepIconComponent={label}
                      {...labelProps}>
                      {stepsNumber === 6 ? labelsIcons[i] : labelsIcons2[i]}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </Grid>
      </Grid>
      <div>
        <Grid>{children}</Grid>
      </div>
    </Box>
  );
}

/* Steps.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
}; */

export default Steps;
