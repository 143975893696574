import React, { useCallback, useMemo } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

/* Project */
import { vehicleYearValidation } from 'utils/functions';
import { darkerGreyOnForms } from 'utils/helper';
import SchedulerStepsStyles from '../../SchedulerSteps.styles';
import CustomAutoComplete from '../CustomAutoComplete';
import { controlsChanged, setControlsErrors } from '../../schedulerSteps.actions';

const disabledFormValue = ({ loading, isFindedVehicle }) => loading || isFindedVehicle;

function VehicleDataSection() {
  const dispatch = useDispatch();

  const { objects } = useSelector((state) => state.app);

  const vin = useSelector((state) => state.schedulerSteps.controls.vin);
  const vinError = useSelector((state) => state.schedulerSteps.controlsErrors.vin);

  const modelId = useSelector((state) => state.schedulerSteps.controls.modelId);
  const modelIdError = useSelector((state) => state.schedulerSteps.controlsErrors.modelId);

  const versionId = useSelector((state) => state.schedulerSteps.controls.versionId);
  const versionIdError = useSelector((state) => state.schedulerSteps.controlsErrors.versionId);

  const yearState = useSelector((state) => state.schedulerSteps.controls.year);
  const yearError = useSelector((state) => state.schedulerSteps.controlsErrors.year);

  const brandId = useSelector((state) => state.schedulerSteps.controls.brandId);

  const loading = useSelector((state) => state.schedulerSteps.loading);
  const isFindedVehicle = useSelector((state) => state.schedulerSteps.isFindedVehicle);

  const { vehicleModels, vehicleVersions } = objects;
  const basicRequiredFieldValidation = (field, errorMessage) => (field === '' ? errorMessage : '');
  const yearFieldValidation = (year) => (!vehicleYearValidation(year) ? 'Campo año inválido' : '');

  const onChange = (event) => {
    const { value, name } = event.target;
    let errorMessage = '';
    if (name === 'year') {
      errorMessage = value
        ? yearFieldValidation(value)
        : basicRequiredFieldValidation(value, 'Campo año requerido');
    }
    if (name === 'versionId') {
      errorMessage = basicRequiredFieldValidation(value, 'Debe seleccionar una versión');
    }
    if (name === 'vin') {
      errorMessage = basicRequiredFieldValidation(value, 'Campo VIN requerido');
    }
    dispatch(
      setControlsErrors({
        controlsErrors: {
          [name]: errorMessage,
        },
      }),
    );
    dispatch(controlsChanged({ name, value }));
  };

  const models = useMemo(
    () =>
      Object.keys(vehicleModels)
        .map((key) => vehicleModels[key])
        .filter((item) => item.brand === brandId),
    [vehicleModels, brandId],
  );

  const versions = useMemo(
    () => Object.keys(vehicleVersions).map((key) => vehicleVersions[key]),
    [vehicleVersions],
  );

  const filteredVehicleVersions = useMemo(
    () => versions.filter((item) => item.modelId === modelId),
    [versions, modelId],
  );

  const handleAutocompleteGetNewValue = ({ name, id }) => {
    const errorMessage = basicRequiredFieldValidation(id, 'Debe seleccionar un modelo');
    dispatch(
      setControlsErrors({
        controlsErrors: {
          [name]: errorMessage,
        },
      }),
    );
    dispatch(controlsChanged({ name, value: id }));
  };

  const onChangeSelect = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange],
  );

  return (
    <Grid id="data-section">
      <Typography color="primary" variant="h6" sx={SchedulerStepsStyles.title}>
        Datos del vehículo
      </Typography>
      <Grid container item sm={12} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={5} lg={2.8} sx={{ marginBottom: '10px' }}>
          <TextField
            id="vin"
            name="vin"
            key="vin"
            label="VIN"
            variant="outlined"
            size="small"
            InputLabelProps={{
              sx: { ...darkerGreyOnForms },
            }}
            fullWidth
            sx={SchedulerStepsStyles.field}
            value={vin}
            onChange={onChange}
            inputProps={{ maxLength: 17 }}
            disabled={disabledFormValue({ loading, isFindedVehicle })}
            error={!!vinError}
          />
          <FormHelperText error={!!vinError}>{vinError}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={2.8} sx={{ marginBottom: '10px' }}>
          <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
            <CustomAutoComplete
              name="modelId"
              value={modelId.toString()}
              options={Object.values(models)}
              disabled={loading}
              size="small"
              label="Seleccionar modelo"
              error={!!modelIdError}
              getNewValue={handleAutocompleteGetNewValue}
            />
            <FormHelperText error={!!modelIdError}>{modelIdError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={2.8} sx={{ marginBottom: '10px' }}>
          <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
            <InputLabel
              id="demo-simple-select-label"
              disabled={loading || modelId === ''}
              sx={{ ...darkerGreyOnForms }}>
              Versión
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="versionId"
              name="versionId"
              key="versionId"
              label="Versión"
              value={versionId}
              onChange={onChangeSelect}
              error={!!versionIdError}
              disabled={loading || modelId === ''}>
              {filteredVehicleVersions.length ? (
                filteredVehicleVersions.map((item) => (
                  <MenuItem key={item.id} sx={SchedulerStepsStyles.menuItem} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={SchedulerStepsStyles.menuItem} disabled>
                  No hay datos disponibles.
                </MenuItem>
              )}
            </Select>
            <FormHelperText error={!!versionIdError}>{versionIdError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={2.8} sx={{ marginBottom: '10px' }}>
          <TextField
            id="year"
            name="year"
            key="year"
            label="Año"
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{
              sx: { ...darkerGreyOnForms },
            }}
            sx={SchedulerStepsStyles.field}
            value={yearState}
            onChange={onChange}
            error={!!yearError}
            helperText={yearError}
            inputProps={{ maxLength: 4 }}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VehicleDataSection;
