/* eslint-disable complexity */

import React, { useEffect, useMemo, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router dom */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { Checkbox, FormHelperText, Grid, Typography } from '@mui/material';

/* Project */
import ErrorDialog from '../dialogs/ErrorDialog';
import ResumeCard from './components/ResumeCard';
import StepsActionButtons from './components/StepsActionButtons';
import getViewsData from './data/viewsData';
import {
  closeInternalError,
  submitReSchedule,
  submitSchedule,
  toggleTermsAndConditions,
  toggleTermsErrors,
} from './schedulerSteps.actions';
import SchedulerStepsStyles from './SchedulerSteps.styles';
import LoaderDialog from '../loaders/LoaderDialog';
import ActionsButtonsContainer from './components/ActionsButtonsContainer';

function ResumeView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.schedulerSteps.loading);
  const controls = useSelector((state) => state.schedulerSteps.controls);
  const termsError = useSelector((state) => state.schedulerSteps.termsError);
  const redirectSuccessScreen = useSelector(
    (state) => state.schedulerSteps.schedulingResponse.redirectSuccessScreen,
  );
  const requestError = useSelector((state) => state.schedulerSteps.requestError);
  const errorType = useSelector((state) => state.schedulerSteps.errorType);
  const addContact = useSelector((state) => state.schedulerSteps.addContact);
  const dateTakenError = useSelector((state) => state.schedulerSteps.dateTakenError);
  const createdSchedulingCode = useSelector((state) => state.schedulerSteps.createdSchedulingCode);
  const view = useSelector((state) => state.app.view);

  // console.log('errors', { requestError, errorType });

  // console.log('redirectSuccessScreen :', redirectSuccessScreen);

  useEffect(() => {
    if (redirectSuccessScreen) {
      navigate(`/${view}/success`);
    }
  }, [redirectSuccessScreen]);

  const welcomeScreenControls = useSelector((state) => state.welcomeScreen.controls);

  const objects = useSelector((state) => state.app.objects);

  const kilometerValue = objects?.kilometers[controls.serviceKm]?.name || controls.serviceKm;

  const { selectedService } = welcomeScreenControls;

  const { regions, workshops, vehicleModels, maintenanceTypes, diagnosisTypes } = objects;

  const { technicians, selectedTechnician } = controls;

  const maintenance = {
    text: 'Mantención',
    data: maintenanceTypes,
  };

  const diagnosis = {
    text: 'Diagnóstico',
    data: diagnosisTypes,
  };

  let serviceValidation = {};
  if (view === 'schedule') {
    serviceValidation = selectedService === 'maintenance' ? maintenance : diagnosis;
  } else {
    serviceValidation = controls.selectedServiceCode === 'maintenance' ? maintenance : diagnosis;
  }

  const regionsData = useMemo(() => Object.keys(regions).map((key) => regions[key]), [regions]);

  const selectedRegion = regionsData.find((r) => r.externalId === controls.region);

  const technicianData = technicians.find(
    (item) => item.employeeId === selectedTechnician.employeeId,
  );

  const selectedWorkshop = workshops[controls.workshop];

  const selectedVehicleModels = vehicleModels[controls.modelId];

  const views = useMemo(
    () =>
      getViewsData({
        controls,
        selectedRegion,
        selectedWorkshop,
        selectedVehicleModels,
        technicianData,
        serviceValidation,
        kilometerValue,
      }),
    [controls],
  );

  const [activePanel, setActivePanel] = useState(views[0].title);

  const handleChangeTerms = () => {
    dispatch(toggleTermsAndConditions());
  };
  // console.log('[dateTakenError] :', dateTakenError);
  // console.log('[createdSchedulingCode] :', createdSchedulingCode);

  const submitScheduleFunction = () => {
    if (!dateTakenError) {
      return dispatch(submitSchedule({ controls, selectedService, addContact, navigate }));
      // eslint-disable-next-line
    } else {
      return dispatch(
        submitReSchedule({
          schedulingCode: createdSchedulingCode,
          technicianId: selectedTechnician.businessPartnerId,
          scheduleStartDate: controls.selectedStartDate,
          scheduleEndDate: controls.selectedEndDate,
        }),
      );
    }
  };

  const handleSubmit = () => {
    if (controls.acceptTermsandConditions) {
      if (view === 'schedule') {
        /* dispatch(submitSchedule({ controls, selectedService, addContact, navigate })); */
        submitScheduleFunction();
      } else if (view === 'reschedule') {
        // console.log('[RESCHEDULE PROCESS]');
        dispatch(
          submitReSchedule({
            schedulingCode: createdSchedulingCode,
            technicianId: selectedTechnician.businessPartnerId,
            scheduleStartDate: controls.selectedStartDate,
            scheduleEndDate: controls.selectedEndDate,
          }),
        );
      } else {
        // TODO Annulment
        // console.log('Annulment');
      }
    } else {
      dispatch(toggleTermsErrors());
    }
  };

  const viewValidation = view === 'schedule' ? 'AGENDAMIENTO' : 'REAGENDAMIENTO';

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        {views
          .filter((viewItem) => viewItem.render)
          .map((item) => (
            <ResumeCard
              key={item.title}
              item={item}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
            />
          ))}
      </Grid>
      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
        <Checkbox
          checked={controls.acceptTermsandConditions}
          onChange={handleChangeTerms}
          sx={SchedulerStepsStyles.termsCheckbox}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography color="primary" variant="subtitle1">
          Al continuar acepto los términos y condiciones por parte de Salfa Automotriz
        </Typography>
      </Grid>

      {!controls.acceptTermsandConditions && termsError && (
        <Grid item xs={12}>
          <FormHelperText error={!!termsError}>
            Para agendar debe aceptar los términos y condiciones
          </FormHelperText>
        </Grid>
      )}
      <ActionsButtonsContainer>
        <StepsActionButtons isLastStep handleNext={handleSubmit} withLoader loading={loading} />
      </ActionsButtonsContainer>
      <ErrorDialog
        open={requestError}
        view={view}
        errorType={errorType}
        handleClose={() => dispatch(closeInternalError())}
      />
      <LoaderDialog open={loading} title={`Reservando ${viewValidation.toLocaleLowerCase()}`} />
    </>
  );
}

export default ResumeView;
