import React from 'react';
import PropTypes from 'prop-types';

/* React Router */
import { Route, Routes, Navigate } from 'react-router-dom';

/* Project */
import Paths from 'utils/paths';
import Layout from '../containers/Layout/Layout';
import Auth from '../layouts/Auth';
import NotFoundPage from '../pages/NotFound/NotFoundPage';

function PageLoading(props) {
  const { loading, component } = props;

  const defaultLoader = (
    <div className="loader-container">
      <span className="loader" />
    </div>
  );

  return loading ? defaultLoader : component;
}

function ProtectedElement(props) {
  const { loading, user, component: Component } = props;

  const authWrapper = (
    <Auth user={user} loading={loading}>
      <Component />
    </Auth>
  );
  const layoutWithWrapper = <Layout>{authWrapper}</Layout>;

  return <PageLoading loading={loading} component={layoutWithWrapper} />;
}

const isAuthRedirect = (user, isAuth) => isAuth && user;

function PublicElement(props) {
  const { loading, component, user, isAuth } = props;

  if (isAuthRedirect(user, isAuth)) {
    return <Navigate to="/" />;
  }

  return <PageLoading loading={loading} component={component} />;
}

function SharedElement(props) {
  const { loading, component } = props;

  return <PageLoading loading={loading} component={component} />;
}

function Router({ user, loading }) {
  const { shared, publics, protecteds } = Paths;

  const sharedElements = shared.map(({ path, component: Component, useLayout }) => ({
    path,
    element: (
      <SharedElement
        loading={loading}
        component={useLayout ? <Layout element={<Component />} /> : <Component />}
      />
    ),
  }));

  const publicElements = publics.map(
    ({ path, component: Component, useLayout, isAuth, title }) => ({
      path,
      element: (
        <PublicElement
          loading={loading}
          user={user}
          isAuth={isAuth}
          component={useLayout ? <Layout element={<Component title={title} />} /> : <Component />}
        />
      ),
    }),
  );

  const protectedElements = protecteds.map(({ path, component }) => ({
    path,
    element: <ProtectedElement component={component} user={user} loading={loading} />,
  }));

  const routes = [...sharedElements, ...publicElements, ...protectedElements];

  return (
    <Routes>
      {routes.map(({ element, path }) => (
        <Route key={path} exact path={path} element={element} />
      ))}
      <Route path="/schedule/steps/*" element={<Navigate to="/" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

Router.propTypes = { user: PropTypes.shape({}), loading: PropTypes.bool };

Router.defaultProps = { user: null, loading: true };

export default Router;
