import React, { useCallback, useRef, useState } from 'react';
/* Material UI */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* Project */
import { controlsChanged, getAppointments } from '../welcomeScreens.actions';
import '../styles.css';
import colors from 'utils/colors';
import useForm from 'hooks/useForm';
import { formatRutNew, validatePatent, validateRut } from 'utils/functions';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { darkerGreyOnForms } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import SchedulerStepsStyles from 'content/features/schedulerSteps/SchedulerSteps.styles';

function AnnulmentForm({ controls, loading, homeStyles, isMobile }) {
  const radioRef = useRef(null);
  const dispatch = useDispatch();
  const view = useSelector((state) => state.app.view);

  const onChangeText = useCallback(
    (id, value) => {
      let newValue = value;
      if (radioRef.current === 'rut' && id === 'annulmentSearchValue') {
        newValue = formatRutNew(value);
      }
      dispatch(controlsChanged({ name: id, value: newValue }));
    },
    [radioRef],
  );

  const { onChange, onSubmit, errors, validate } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      annulmentParameter: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Debe seleccionar una opción ',
        },
      ],
      annulmentSearchValue: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un valor',
        },
        {
          check: (value) => (radioRef.current === 'rut' ? validateRut(value) : true),
          message: 'RUT inválido',
        },
        {
          check: (value) => (radioRef.current === 'patent' ? validatePatent(value) : true),
          message: 'Patente inválida',
        },
      ],
    },
  });

  const onChangeRadio = useCallback(
    ({ target }) => {
      radioRef.current = target.value;
      const event = { type: 'click', target: { name: 'annulmentParameter', value: target.value } };
      onChange(event);
      if (controls.annulmentParameter !== '') {
        validate('annulmentSearchValue', controls.annulmentSearchValue);
      }
    },
    [onChange],
  );

  const handleSubmit = () => {
    const { annulmentParameter, annulmentSearchValue } = controls;
    onSubmit(() =>
      dispatch(
        getAppointments({
          searchValue: annulmentSearchValue,
          searchParameter: annulmentParameter,
          view,
        }),
      ),
    );
  };

  const limitRutField = radioRef.current === 'rut' ? { maxLength: 10 } : {};
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid
        sx={homeStyles.container}
        container
        direction="column"
        lg={6}
        md={8}
        sm={8}
        xs={12}
        // alignItems="center"
        flexWrap="wrap">
        <Grid>
          <FormControl>
            <RadioGroup
              row={!isMobile}
              id="annulmentParameter"
              name="annulmentParameter"
              value={controls.annulmentParameter}
              onChange={onChangeRadio}>
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="rut"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">RUT</Typography>}
              />
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="patent"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">Patente</Typography>}
              />
              <FormControlLabel
                id="annulmentParameter"
                name="annulmentParameter"
                value="idTicket"
                control={<Radio sx={homeStyles.radio} />}
                label={<Typography color="primary">ID Ticket</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <FormHelperText error={!!errors.annulmentParameter[0]}>
            {errors.annulmentParameter[0]}
          </FormHelperText>
        </Grid>

        <Grid sx={SchedulerStepsStyles.searchContainer}>
          <TextField
            color="primary"
            label="Ingresar RUT, patente o ID Ticket"
            InputLabelProps={{
              sx: { ...darkerGreyOnForms },
            }}
            variant="outlined"
            inputProps={{ ...limitRutField }}
            sx={SchedulerStepsStyles.patentField}
            size="small"
            id="annulmentSearchValue"
            name="annulmentSearchValue"
            key="annulmentSearchValue"
            value={controls.annulmentSearchValue}
            onChange={onChange}
            fullWidth
            error={!!errors.annulmentSearchValue[0]}
            helperText={errors.annulmentSearchValue[0]}
          />
          <LoadingButton
            loading={loading}
            color="lightBlue"
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={SchedulerStepsStyles.searchButton}>
            <SearchIcon sx={{ marginRight: '0.5rem', marginLeft: '0.5rem', color: 'white' }} />
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AnnulmentForm;
