import * as React from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';

/* Project */
import useForm from 'hooks/useForm';
import { validateEmailForm } from 'utils/functions';
import { darkerGreyOnForms } from 'utils/helper';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import { addUserContact } from '../schedulerSteps.actions';

function ContactDialog(props) {
  const { open, handleClose, isEdit, onChangeText, handleUpdateContact } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const contactName = useSelector((state) => state.schedulerSteps.controls.contactName);
  const contactLastName = useSelector((state) => state.schedulerSteps.controls.contactLastName);
  const contactPhone = useSelector((state) => state.schedulerSteps.controls.contactPhone);
  const contactEmail = useSelector((state) => state.schedulerSteps.controls.contactEmail);
  const selectedIndexContact = useSelector(
    (state) => state.schedulerSteps.controls.selectedIndexContact,
  );
  const contacts = useSelector((state) => state.schedulerSteps.controls.contacts);
  const { onChange, onSubmit, errors, clearErrors } = useForm(
    {
      contactName,
      contactLastName,
      contactPhone,
      contactEmail,
      selectedIndexContact,
    },
    null,
    {
      onChange: onChangeText,
      validations: {
        contactName: [
          {
            check: (value) => value.toString().trim().length > 0,
            message: 'Debe ingresar un nombre ',
          },
        ],
        contactLastName: [
          {
            check: (value) => value.toString().trim().length > 0,
            message: 'Debe ingresar un apellido',
          },
        ],
        contactPhone: [
          {
            check: (value) => value.toString().trim().length > 0,
            message: 'Debe ingresar un teléfono',
          },
          {
            check: (value) => value.toString().trim().length === 9,
            message: 'El teléfono debe contener 9 caracteres',
          },
          {
            check: (value) => /^[0-9]+$/.test(value),
            message: 'Campo teléfono solo admite números',
          },
        ],
        contactEmail: [
          {
            check: (value) => value.toString().trim().length > 0,
            message: 'Debe ingresar un correo',
          },
          {
            check: (value) => validateEmailForm(value.toString().trim()),
            message: 'Correo inválido',
          },
          {
            check: (value) => !contacts.find((contact) => contact.email === value),
            message: 'Ya existe un contacto/cliente con este mail',
          },
        ],
      },
    },
  );

  const submitContactDialog = () => {
    if (isEdit) {
      handleClose();
    } else {
      dispatch(
        addUserContact([
          {
            id: contactName,
            name: contactName,
            lastName: contactLastName,
            email: contactEmail,
            phone: contactPhone,
            fromSite: true,
          },
        ]),
      );
      handleClose();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (selectedIndexContact !== -1) {
      handleUpdateContact({
        newSelectedIndexContact: selectedIndexContact,
        contactValues: {
          contactName,
          contactLastName,
          contactPhone,
          contactEmail,
        },
      });
    }

    return onSubmit(() => submitContactDialog());
  };

  const fieldHelperText = (error, text) => error || text;
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title" sx={SchedulerStepsStyles.dialogTitle}>
          DATOS DEL CONTACTO
        </DialogTitle>
        <DialogContent>
          <form style={{ width: '100%' }} onSubmit={submitForm}>
            <Grid
              item
              xs={12}
              sx={{ mt: 2 }}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between">
              <Grid flex={1} xs={12} sm={6} sx={SchedulerStepsStyles.field}>
                <TextField
                  id="contactName"
                  name="contactName"
                  key="contactName"
                  value={contactName}
                  onChange={onChange}
                  label="Nombre"
                  variant="outlined"
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  size="small"
                  sx={{ ...SchedulerStepsStyles.field, ...(!isMobile && { paddingRight: '5px' }) }}
                  fullWidth
                  error={!!errors.contactName[0]}
                  helperText={fieldHelperText(errors.contactName[0], 'Nombre del contacto')}
                />
              </Grid>
              <Grid xs={12} sm={6} sx={SchedulerStepsStyles.field}>
                <TextField
                  id="contactLastName"
                  name="contactLastName"
                  key="contactLastName"
                  value={contactLastName}
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  onChange={onChange}
                  label="Apellido"
                  variant="outlined"
                  size="small"
                  sx={{ ...SchedulerStepsStyles.field, ...(!isMobile && { paddingLeft: '5px' }) }}
                  fullWidth
                  error={!!errors.contactLastName[0]}
                  helperText={fieldHelperText(errors.contactLastName[0], 'Apellido del contacto')}
                />
              </Grid>
              <Grid xs={12} sm={6} sx={SchedulerStepsStyles.field}>
                <TextField
                  id="contactPhone"
                  name="contactPhone"
                  key="contactPhone"
                  value={contactPhone}
                  onChange={onChange}
                  label="Teléfono"
                  variant="outlined"
                  size="small"
                  sx={{ ...SchedulerStepsStyles.field, ...(!isMobile && { paddingRight: '5px' }) }}
                  fullWidth
                  error={!!errors.contactPhone[0]}
                  helperText={fieldHelperText(errors.contactPhone[0], 'Teléfono del contacto')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ ...darkerGreyOnForms }}>+56</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} sx={SchedulerStepsStyles.field}>
                <TextField
                  id="contactEmail"
                  name="contactEmail"
                  key="contactEmail"
                  value={contactEmail}
                  onChange={onChange}
                  label="Correo"
                  InputLabelProps={{
                    sx: { ...darkerGreyOnForms },
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ ...SchedulerStepsStyles.field, ...(!isMobile && { paddingLeft: '5px' }) }}
                  fullWidth
                  error={!!errors.contactEmail[0]}
                  helperText={fieldHelperText(errors.contactEmail[0], 'Correo del contacto')}
                />
              </Grid>
            </Grid>
          </form>
          <Box sx={SchedulerStepsStyles.dialogActions}>
            <Button
              variant="outlined"
              color="lightBlue"
              onClick={() => {
                clearErrors();
                handleClose();
              }}>
              cerrar
            </Button>
            <Button
              color="lightBlue"
              variant="contained"
              sx={{ color: 'white' }}
              onClick={submitForm}>
              guardar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ContactDialog;
