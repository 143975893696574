import React, { useState } from 'react';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Material UI */
import { Grid, Typography } from '@mui/material';
import { LoginActions } from 'content/features/authentication';
import { Footer, NavBar, TemporaryDrawer } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const signedByGoogle = useSelector((state) => state.app.signedByGoogle);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    dispatch(LoginActions.logout({ user, signedByGoogle }));
  };

  return (
    <Grid
      justifyContent="space-between"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}>
      <NavBar setOpenDrawer={setOpenDrawer} user={user} handleLogout={handleLogout} />
      <Grid container justifyContent="center" sx={{ alignSelf: 'flex-start', flexGrow: 1 }}>
        <Grid container item md={9} sm={12}>
          {element || children}
        </Grid>
      </Grid>
      <Footer />
      <TemporaryDrawer openByDefault={openDrawer} setOpenDrawer={setOpenDrawer} />
    </Grid>
  );
}

export default Layout;
