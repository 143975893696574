const confirmCardStyles = {
  card: {
    paddingX: { xs: '10px', md: '42px' },
  },
  titleCard: {
    fontSize: '20px',
    marginBottom: '10px',
    color: '#002855',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    fontFamily: 'Open Sans, sans-serif',
    textTransform: 'none',
  },
  subtitleCard: {
    fontSize: '16px',
    marginBottom: '36px',
    fontFamily: 'Open Sans, sans-serif',
  },
  title: {
    fontSize: '20px',
    marginBottom: '15px',
    color: '#002855',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    fontFamily: 'Open Sans, sans-serif',
  },
  subtitle: {
    fontSize: '12px',
    marginBottom: '10px',
    color: 'grey',
    fontFamily: 'Open Sans, sans-serif',
  },
  data: {
    fontSize: '16px',
    marginBottom: '20px',
    fontFamily: 'Open Sans, sans-serif',
  },
  button: {
    contained: {
      fontFamily: 'Open Sans, sans-serif',
      backgroundColor: '#E10600',
      textTransform: 'none',
      fontSize: '15px',
      width: { xs: '100%' },
    },
    outlined: {
      color: '#E10600',
      borderColor: '#E10600',
      fontFamily: 'Open Sans, sans-serif',
      textTransform: 'none',
      fontSize: '15px',
      width: { xs: '100%' },
    },
    text: {
      color: '#E10600',
      fontFamily: 'Open Sans, sans-serif',
      textTransform: 'none',
      fontSize: '15px',
      width: { xs: '100%' },
    },
  },
  buttonRow: {
    flexDirection: { xs: 'column-reverse', md: 'row' },
    justifyContent: { xs: 'center', md: 'flex-end' },
  },
  validationText: {
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '30px',
    fontFamily: 'Open Sans, sans-serif',
  },
};

export default confirmCardStyles;
