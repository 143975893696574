import React from 'react';

/* Material UI */
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

/* Project */
import { darkerGreyOnForms } from 'utils/helper';
import SchedulerStepsStyles from '../SchedulerSteps.styles';

function SelectTechnician(props) {
  const { controls, onChange, errors, calendarLoading } = props;
  const { technicians, selectedHour } = controls;

  return (
    <Grid lg={6} md={8} sm={12} sx={{ marginBottom: '10px' }}>
      <FormControl fullWidth size="small" sx={SchedulerStepsStyles.field}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{ ...darkerGreyOnForms }}
          disabled={calendarLoading}>
          Técnico
        </InputLabel>
        <Select
          id="selectedTechnician"
          name="selectedTechnician"
          key="selectedTechnician"
          value={controls.selectedTechnician}
          onChange={onChange}
          error={!!errors.selectedTechnician[0]}
          labelId="demo-simple-select-label"
          label="Técnico"
          disabled={calendarLoading}
          fullWidth>
          {technicians.length ? (
            technicians.map((item) => (
              <MenuItem key={item.name} value={item} sx={SchedulerStepsStyles.menuItem}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled sx={SchedulerStepsStyles.menuItem}>
              Esta sucursal no tiene técnicos habilitados.
            </MenuItem>
          )}
        </Select>
        <FormHelperText error={!!errors.selectedTechnician[0]}>
          {errors.selectedTechnician[0]}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}

export default SelectTechnician;
