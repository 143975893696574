import React, { useCallback, useEffect, useRef } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import {
  Grid,
  Typography,
  FormControl,
  Button,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormHelperText,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

/* Project */
import { getScheduleStateFromApi, setView } from 'content/app.actions';
import { formatRutNew, validatePatent, validateRut } from 'utils/functions';
import useForm from 'hooks/useForm';
import ErrorDialog from 'content/features/dialogs/ErrorDialog';
import LoaderDialog from 'content/features/loaders/LoaderDialog';
import { darkerGreyOnForms } from 'utils/helper';
import homeStyles from '../home.styles';
import { closeNoRecordsError, controlsChanged, getAppointments } from '../welcomeScreens.actions';
import { LoadingButton } from '@mui/lab';

function RescheduleWelcome() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const controls = useSelector((state) => state.welcomeScreen.controls);
  const loading = useSelector((state) => state.welcomeScreen.loading);
  const error = useSelector((state) => state.welcomeScreen.error);
  const errorType = useSelector((state) => state.welcomeScreen.errorType);
  const view = useSelector((state) => state.app.view);

  useEffect(() => {
    dispatch(setView({ value: 'reschedule' }));
    dispatch(getScheduleStateFromApi());
  }, []);
  const radioRef = useRef(null);

  const onChangeText = useCallback((id, value) => {
    let newValue = value;
    if (radioRef.current === 'rut' && id === 'rescheduleSearchValue') {
      newValue = formatRutNew(value);
    }
    dispatch(controlsChanged({ name: id, value: newValue }));
  }, []);

  const { onChange, onSubmit, errors, validate } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      rescheduleParameter: [
        {
          check: (value) => value !== '' && value.toString().trim().length > 0,
          message: 'Debe seleccionar una opción ',
        },
      ],
      rescheduleSearchValue: [
        {
          check: (value) => value.toString().trim().length > 0,
          message: 'Debe ingresar un valor',
        },
        {
          check: (value) => (radioRef.current === 'rut' ? validateRut(value) : true),
          message: 'RUT inválido',
        },
        {
          check: (value) => (radioRef.current === 'patent' ? validatePatent(value) : true),
          message: 'Patente inválida',
        },
      ],
    },
  });

  const onChangeRadio = useCallback(
    ({ target }) => {
      radioRef.current = target.value;
      const event = { type: 'click', target: { name: 'rescheduleParameter', value: target.value } };
      onChange(event);
      if (controls.rescheduleParameter !== '') {
        validate('rescheduleSearchValue', controls.rescheduleSearchValue);
      }
    },
    [onChange],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const { rescheduleParameter, rescheduleSearchValue } = controls;
    onSubmit(() =>
      dispatch(
        getAppointments({
          searchValue: rescheduleSearchValue,
          searchParameter: rescheduleParameter,
          view,
        }),
      ),
    );
  };

  const limitRutField = radioRef.current === 'rut' ? { maxLength: 10 } : {};

  return (
    <Grid>
      <Grid>
        <Typography color="primary" variant="h5" sx={homeStyles.title}>
          Reagenda tu hora
        </Typography>
        <Typography color="primary">
          Selecciona el tipo de búsqueda que quieres hacer, y luego busca para ver los agendamientos
          relacionados.
        </Typography>
      </Grid>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid
          sx={homeStyles.container}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap">
          <Grid>
            <FormControl>
              <RadioGroup
                row={!isMobile}
                id="rescheduleParameter"
                name="rescheduleParameter"
                value={controls.rescheduleParameter}
                onChange={onChangeRadio}>
                <FormControlLabel
                  id="rescheduleParameter"
                  name="rescheduleParameter"
                  value="rut"
                  control={<Radio sx={homeStyles.radio} />}
                  label={<Typography color="primary">RUT</Typography>}
                  disabled={loading}
                />
                <FormControlLabel
                  id="rescheduleParameter"
                  name="rescheduleParameter"
                  value="patent"
                  control={<Radio sx={homeStyles.radio} />}
                  label={<Typography color="primary">Patente</Typography>}
                  disabled={loading}
                />
                <FormControlLabel
                  id="rescheduleParameter"
                  name="rescheduleParameter"
                  value="idTicket"
                  control={<Radio sx={homeStyles.radio} />}
                  label={<Typography color="primary">ID Ticket</Typography>}
                  disabled={loading}
                />
              </RadioGroup>
            </FormControl>
            <FormHelperText error={!!errors.rescheduleParameter[0]}>
              {errors.rescheduleParameter[0]}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} sm={12} md={6} display="flex" gap={1} sx={homeStyles.searchContainer}>
            <TextField
              color="primary"
              label="Ingresar RUT, patente o ID Ticket"
              variant="outlined"
              InputLabelProps={{
                sx: { ...darkerGreyOnForms },
              }}
              fullWidth
              size="small"
              inputProps={{ ...limitRutField }}
              sx={homeStyles.field}
              id="rescheduleSearchValue"
              name="rescheduleSearchValue"
              key="rescheduleSearchValue"
              value={controls.rescheduleSearchValue}
              onChange={onChange}
              error={!!errors.rescheduleSearchValue[0]}
              helperText={errors.rescheduleSearchValue[0]}
              disabled={loading}
            />
            <LoadingButton type="submit" loading={loading} color="lightBlue" variant="contained">
              <SearchIcon sx={{ marginRight: '0.5rem', marginLeft: '0.5rem', color: 'white' }} />
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <ErrorDialog
        open={error}
        view="reschedule"
        errorType={errorType}
        handleClose={() => dispatch(closeNoRecordsError())}
      />
      <LoaderDialog open={loading} title="Buscando agendamientos" />
    </Grid>
  );
}

export default RescheduleWelcome;
